@import "scss/resources";

.TemplateFileRecipients {
  position: relative;
  width: 100%;
  max-width: 57.8rem;
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  padding: 3rem 6rem;
  border: 1px solid $grey-30;
  border-radius: 15px;

  & .main {
    display: flex;
    flex-direction: column;
    transition: opacity 0.2s ease-in-out;

    &.progress {
      opacity: 0.3;
    }
  }

  & .selfSigning {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem;
    border: 1px solid $grey-30;
    border-radius: 10px;
    margin-bottom: 3rem;

    & .checkbox {
      display: flex;
      gap: 0.8rem;

      & .checkboxLabel {
        @include textU(1.6rem, 400);
      }
    }
  }
}
