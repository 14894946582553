@import "scss/resources";

.GuestDocReady {
  width: 100%;
  min-height: calc(100svh - 10rem);
  margin: 5rem auto;
  gap: 30px;
  color: $black;

  & .wrap {
    position: relative;
    width: 100%;
    min-height: calc(100svh - 10rem);
    height: 80rem;
  }

  & .main {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 44.4rem;
    min-height: 50rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(-50%, -50%);
  }

  & .linkToMain {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  & .downloadLink {
    @include textU(1.6rem, 500);

    color: $green;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  & .title {
    @include textC(3.2rem, 400);

    margin: 2.5rem 0 1.5rem;
  }

  & .info {
    @include textU(1.6rem, 400);

    max-width: 38.6rem;
    text-align: center;
  }

  & .buttons {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-top: 4rem;
  }

  & .button {
    max-width: 180px;
  }
}
