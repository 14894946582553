@import "scss/resources";

.Input {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  height: 5rem;
  width: 100%;
  padding: 0 1.6rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  background: $grey-10;
  border: 1px solid $grey-10;
  border-radius: 10px;
  color: $black;
  transition: all 0.1s ease-in-out;

  & svg.stroke {
    path {
      stroke: $grey-70;
      transition: all 0.1s ease-in-out;
    }
  }
  & svg.fill {
    path {
      fill: $grey-70;
      transition: all 0.1s ease-in-out;
    }
  }

  &::placeholder {
    color: $grey-70;
  }

  &:has(input:focus) {
    background: $white;
    border: 1px solid $grey-40;
  }

  & .label {
    @include textU(1.2rem, 400);

    position: absolute;
    top: -20px;
    left: 0;

    & .labelError {
      color: $red;
    }
  }

  & .addText {
    @include textU(1.2rem, 400);

    position: absolute;
    top: 5.3rem;
    left: 0;
    white-space: pre-line;
    line-height: 0.6rem;
  }

  &.disabled {
    background: $grey-10 !important;
    border-color: $grey-10 !important;

    & .originalInput {
      color: $grey-80 !important;

      &::placeholder {
        color: $grey-80 !important;
      }
    }

    & svg.stroke {
      path {
        stroke: $grey-40;
      }
    }
    & svg.fill {
      path {
        fill: $grey-40;
      }
    }
  }

  &.withValue {
    & .originalInput {
      color: $black;
    }

    & svg.stroke {
      path {
        stroke: $black;
      }
    }
    & svg.fill {
      path {
        fill: $black;
      }
    }
  }

  &.withError {
    border: 1px solid $red !important;

    & .addText {
      color: $red !important;
    }
  }

  &.boundless {
    height: 1.6rem;
    padding: 0;
    margin: 0;
    border: none !important;
    border-top: 1px solid transparent !important;
    border-bottom: 1px solid $grey-40 !important;
    background: transparent !important;
  }

  & .inputWithIcon {
    width: calc(100% + 3.2rem);
    margin-left: -1.6rem;
    margin-right: -1.6rem;
    padding: 0 1.6rem;
    height: 100%;
    display: flex;
    gap: 0.8rem;

    &.inputWithSecondIcon {
      width: calc(100% - 3rem);
    }
  }

  & .originalInput {
    @include textU(1.6rem, 400);

    width: calc(100% + 3.2rem);
    margin-left: -1.6rem;
    margin-right: -1.6rem;
    padding: 0 1.6rem;
    height: calc(100% - 2px);
    background: none;
    border: none;
    color: $grey-70;

    &:disabled {
      cursor: default;
    }
  }

  &:hover {
    border: 1px solid $grey-40;
  }

  & .clickable {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  & .clearButton {
    display: none;
  }

  &.withValue .clearButton {
    display: block;
    cursor: pointer;
  }
}
