@import "scss/resources";

.ProfileActions {
  @include textU(1.4rem, 400);

  position: absolute;
  top: 6rem;
  right: 1rem;
  width: 18rem;
  padding: 2.5rem 1.5rem;
  border: 1px solid $grey-40;
  border-radius: 10px;
  background: $white;
  color: $black;
  z-index: 2;

  & .menuList {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 30px;

    & .menuItem {
      @include textU(1.6rem, 400);

      display: flex;
      align-items: center;
      gap: 8px;
      color: $black;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }

      &.topBorder {
        margin-top: -1rem;
        padding-top: 2rem;
        border-top: 1px solid $grey-30;
      }
    }
  }
}
