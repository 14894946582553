@import "scss/resources";

.MovingProgressModal {
  position: relative;
  width: 36.5rem;
  height: 7.3rem;
  padding: 1.5rem 2rem;
  display: grid;
  grid-template-columns: min-content auto min-content;
  grid-template-rows: 1fr 1fr;
  gap: 10px 15px;
  background: $white;
  border-radius: 10px;
  color: $black;
  box-shadow: 0px 1px 4px 0px #00000026;

  & .icon {
    grid-row: 1 / 3;
    align-self: center;
  }

  & .title {
    @include textC(1.8rem, 400);
    @include threeDots;
  }

  & .progressBar {
    position: relative;
    grid-column: 2 / 4;
    width: 100%;
    height: 0.8rem;
    border-radius: 20px;
    background-color: $grey-20;

    & .success {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 0.8rem;
      border-radius: 20px;
      background-color: $green-action;
      transition: width ease-in-out 0.3s;
    }
  }

  & .cancelButton {
    @include textC(1.2rem, 400);

    height: min-content;
    margin-top: 0.5rem;
  }
}
