@import "scss/resources";

.UpdateContactModal {
  position: relative;
  width: 50rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 10px;
  color: $black;
  box-shadow: 0px 1px 4px 0px #00000026;

  .modalHeader {
    & .close {
      position: absolute;
      top: 2rem;
      right: 2rem;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }

    & .title {
      @include textC(2.4rem, 400);
    }

    & .infoWrap {
      @include textU(1.6rem, 400);

      margin-top: 1.5rem;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid $grey-30;

      & p {
        margin-top: 1rem;
      }
    }
  }

  & .buttons {
    margin-top: 2rem;
    display: flex;
    justify-content: stretch;
    gap: 15px;
  }
}
