@import "scss/resources";

.ActionsHistoryModal {
  position: relative;
  width: 65rem;
  max-width: 90vw;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 10px;
  color: $black;
  box-shadow: 0px 1px 4px 0px #00000026;

  .actionsList {
    @include scrollbar();

    display: flex;
    flex-direction: column;
    max-height: 47rem;
    overflow-y: auto;
  }

  .action {
    @include textU(1.4rem, 400);

    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding: 1.5rem 0;
    border-bottom: 1px solid $grey-30;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .title {
    @include textU(1.6rem, 400);

    text-transform: capitalize;
  }

  & .info {
    display: flex;
    flex-wrap: wrap;

    & > *:not(:last-child)::after {
      content: "";
      display: inline-block;
      width: 0.4rem;
      height: 0.4rem;
      background: $grey-40;
      border-radius: 100%;
      margin-left: 1rem;
      margin-right: 1rem;
    }
  }
}
