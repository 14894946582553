@import "scss/resources";

// @keyframes spinner-animation {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }

// .CircleProgressBar {
//   min-width: max-content;
//   // transform: rotate(-90deg);
//   transition: all 0.1s ease-in;
//   animation: spinner-animation 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
// }

.CircleProgressBar {
  display: inline-block;
  position: relative;
  width: 8rem;
  height: 8rem;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 80%;
    height: 80%;
    margin: 10%;
    border: 2px solid $green-action;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $green-action transparent transparent transparent;
  }
  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
