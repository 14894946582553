@import "scss/resources";

.PremiumModal {
  width: 41.2rem;
  padding: 3rem 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: $linear-black;
  border-radius: 20px;
  color: $white;

  & .title {
    @include textC(2.4rem, 400);
  }

  & .privileges {
    @include textU(1.6rem, 400);

    display: grid;
    grid-template-columns: 1.3fr 1fr 1fr;
    grid-template-rows: repeat(4, min-content);
    gap: 20px 0;

    & .active {
      color: $green-action;
    }

    & .inactive {
      color: $grey-70;
    }

    & .centered {
      text-align: center;
      height: 2.4rem;
    }
  }

  & .letsText {
    @include textC(1.8rem, 400);
  }

  & .cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-bottom: 3rem;
  }

  & .card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    padding: 4.2rem 2.5rem;
    background-color: $black;
    border: 1px solid $grey-90;
    border-radius: 24px;
    cursor: pointer;

    &:hover {
      border-color: $green-action;
    }

    &.active {
      border-color: $green-action;
      background: $black right/contain no-repeat
        url("../../../assets/img/premium-card-bg.svg");

      &::after {
        content: "";
        position: absolute;
        bottom: -2.5rem;
        left: 50%;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 100%;
        background-color: $green-action;
        transform: translateX(-50%);
      }
    }

    & .title {
      @include textC(2.4rem, 400);
    }

    & .price {
      @include textC(2.4rem, 400);

      color: $green-action;
    }

    & .info {
      @include textU(1.4rem, 400);
    }
  }

  & .submitButton {
    align-self: center;
  }

  & .closeButton {
    align-self: center;
  }
}
