@import "scss/resources";

.RejectSigningModal {
  position: relative;
  width: 50rem;
  max-width: 90vw;;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 10px;
  color: $black;
  box-shadow: 0px 1px 4px 0px #00000026;

  & .buttons {
    margin-top: 2rem;
    display: flex;
    justify-content: stretch;
    gap: 15px;
  }

  & .confirmButton {
    color: $red !important;

    &:hover {
      border-color: $red !important;
    }

    &.positive {
      color: $black !important;

      &:hover {
        border-color: $black !important;
      }
    }
  }

  & .label {
    @include textU(1.2rem, 400);

    margin-top: 2rem;
  }

  & .details {
    @include textU(1.6rem, 400);

    width: 100%;
    height: 18.3rem;
    background: $grey-10;
    border-radius: 10px;
    padding: 1.2rem 1.6rem;
    border: none;
    color: $black;
    resize: none;

    &::placeholder {
      color: $grey-70;
    }
  }
}
