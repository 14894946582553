@import "scss/resources";

.Checkbox {
  position: relative;
  cursor: pointer;
  // user-select: none;

  & .hiddenCheckbox {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
    cursor: pointer;
  }

  &.withTitle {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.fakeDisabled {
    & input ~ .checkmark {
      border-color: $grey-30 !important;
    }

    &:hover input ~ .checkmark {
      border-color: $grey-30 !important;
    }
  }

  & .checkmark {
    @include centerContent;

    width: 2rem;
    height: 2rem;
    border: 1px solid $grey-50;
    border-radius: 5px;
    transition: 0.2s ease all;
  }

  &:hover {
    & .title {
      color: $green-action;
    }

    & input ~ .checkmark {
      border-color: $black;
    }
  }

  & input:checked ~ .checkmark {
    border-color: $black;
  }

  & .title {
    @include textU(1.6rem, 400);

    color: $black;
  }

  &.white {
    & .title {
      color: $white;
    }

    & input:checked ~ .checkmark {
      border-color: $white;
    }

    &:hover {
      & .title {
        color: $white;
      }

      & input ~ .checkmark {
        border-color: $white;
      }
    }
  }
}
