@import "scss/resources";

.Subscription {
  width: 35.8rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  & .mobileInfo {
    @include textU(1.6rem, 400);

    width: 100%;
    padding: 2.4rem 1.6rem;
    display: flex;
    align-items: flex-start;
    gap: 1.6rem;
    background: $grey-10;
    border-radius: 10px;
    border: 1px solid $grey-20;

    & .mobileImg {
      padding: 0.4rem;
    }
  }
}
