@import "scss/resources";

.Dashboard {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 2rem;

  & .header {
    display: flex;
    justify-content: space-between;
    padding: 0 3rem;
  }

  & .controls {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  & .search {
    width: 40rem;
    height: 5rem;
  }

  & .account {
    @include centerContent;

    position: relative;
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    background-color: $grey-20;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
      // opacity: 0.7;
      border-color: $black;
    }
  }

  & .uploadButton {
    width: 16rem;
    position: relative;

    &:hover button {
      color: $green-action;
      cursor: pointer;
    }

    & .fileInput {
      width: 100%;
      height: 100%;
      position: absolute;
      color: transparent;
      cursor: pointer;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      z-index: 2;

      &::-webkit-file-upload-button {
        display: none;
      }
      &::file-selector-button {
        display: none;
      }
    }

    & .controlButton {
      width: 16rem;
    }
  }
}

.UploadModal {
  position: fixed;
  top: auto !important;
  left: auto !important;
  bottom: 3rem !important;
  right: 3rem !important;
  transform: none !important;
  z-index: 2;
}
