@import "scss/resources";

.CertifyModal {
  position: relative;
  width: 50rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 10px;
  color: $black;
  box-shadow: 0px 1px 4px 0px #00000026;

  & .input {
    margin: 2rem 0 2.5rem;
  }

  & .buttonInner {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }
}
