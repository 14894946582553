@import "scss/resources";

.Button {
  @include centerContent;
  @include textU(1.6rem, 400);

  width: 100%;
  height: 5rem;
  display: flex;
  gap: 8px;
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;

  & svg {
    path {
      transition: all 0.2s ease-in-out;
    }
  }

  &.sm {
    width: min-content;
    white-space: nowrap;
    height: 2.2rem;
  }

  &.md {
    width: 15rem;
    height: 4rem;
  }

  &.lg {
    width: 30rem;
    height: 5rem;
  }

  &.rounded {
    border-radius: 100px;
  }

  &.textWhite {
    border-color: transparent;
    background-color: transparent;
    color: $white;
    & svg.stroke {
      path {
        stroke: $white;
      }
    }
    & svg.fill {
      path {
        fill: $white;
      }
    }

    &:hover {
      color: $green-action;
      & svg.stroke {
        path {
          stroke: $green-action;
        }
      }
      & svg.fill {
        path {
          fill: $green-action;
        }
      }
    }
    &:active {
      opacity: 0.2;
      color: $white;
      & svg.stroke {
        path {
          stroke: $white;
        }
      }
      & svg.fill {
        path {
          fill: $white;
        }
      }
    }
    &:disabled {
      color: $grey-70;
      opacity: 1;
      cursor: default;

      & svg.stroke {
        path {
          stroke: $grey-70;
        }
      }
      & svg.fill {
        path {
          fill: $grey-70;
        }
      }
    }
  }

  &.textBlack {
    border-color: transparent;
    background-color: transparent;
    color: $black;

    & svg.stroke {
      path {
        stroke: $black;
      }
    }
    & svg.fill {
      path {
        fill: $black;
      }
    }

    &:hover {
      color: $grey-80;
      & svg.stroke {
        path {
          stroke: $grey-80;
        }
      }
      & svg.fill {
        path {
          fill: $grey-80;
        }
      }
    }
    &:active {
      opacity: 0.2;
      color: $black;
      & svg.stroke {
        path {
          stroke: $black;
        }
      }
      & svg.fill {
        path {
          fill: $black;
        }
      }
    }
    &:disabled {
      color: $grey-70;
      opacity: 1;
      cursor: default;

      & svg.stroke {
        path {
          stroke: $grey-70;
        }
      }
      & svg.fill {
        path {
          fill: $grey-70;
        }
      }
    }
  }

  &.outlined {
    border-color: $white;
    background-color: transparent;
    color: $white;
    & svg.stroke {
      path {
        stroke: $white;
      }
    }
    & svg.fill {
      path {
        fill: $white;
      }
    }

    &:hover {
      border-color: $green-action;
      color: $green-action;
      & svg.stroke {
        path {
          stroke: $green-action;
        }
      }
      & svg.fill {
        path {
          fill: $green-action;
        }
      }
    }
    &:active {
      opacity: 0.2;
      color: $white;
      & svg.stroke {
        path {
          stroke: $white;
        }
      }
      & svg.fill {
        path {
          fill: $white;
        }
      }
    }
    &:disabled {
      color: $grey-50;
      border-color: $grey-50;
      opacity: 1;
      cursor: default;

      & svg.stroke {
        path {
          stroke: $grey-50;
        }
      }
      & svg.fill {
        path {
          fill: $grey-50;
        }
      }
    }
  }

  &.secondary {
    border-color: transparent;
    background-color: $grey-10;
    color: $black;
    & svg.stroke {
      path {
        stroke: $black;
      }
    }
    & svg.fill {
      path {
        fill: $black;
      }
    }

    &:hover {
      border-color: $black;
      background-color: $grey-10;
      color: $black;
      & svg.stroke {
        path {
          stroke: $black;
        }
      }
      & svg.fill {
        path {
          fill: $black;
        }
      }
    }
    &:active {
      border-color: transparent;
      opacity: 0.2;
      color: $black;
      & svg.stroke {
        path {
          stroke: $black;
        }
      }
      & svg.fill {
        path {
          fill: $black;
        }
      }
    }
    &:disabled {
      border-color: transparent;
      color: $grey-50;
      opacity: 1;
      cursor: default;
      & svg.stroke {
        path {
          stroke: $grey-50;
        }
      }
      & svg.fill {
        path {
          fill: $grey-50;
        }
      }
    }
  }

  &.primary {
    border-color: $black;
    background-color: $black;
    color: $white;
    & svg.stroke {
      path {
        stroke: $white;
      }
    }
    & svg.fill {
      path {
        fill: $white;
      }
    }

    &:hover {
      border-color: $black;
      background-color: $black;
      color: $green-action;
      & svg.stroke {
        path {
          stroke: $green-action;
        }
      }
      & svg.fill {
        path {
          fill: $green-action;
        }
      }
    }
    &:active {
      border-color: black;
      opacity: 0.2;
      color: $black;
      & svg.stroke {
        path {
          stroke: $black;
        }
      }
      & svg.fill {
        path {
          fill: $black;
        }
      }
    }
    &:disabled {
      border-color: transparent;
      background-color: $grey-20;
      color: $grey-50;
      opacity: 1;
      cursor: default;

      & svg.stroke {
        path {
          stroke: $grey-50;
        }
      }
      & svg.fill {
        path {
          fill: $grey-50;
        }
      }
    }
  }

  &.filled {
    border-color: transparent;
    background-color: $white;
    color: $black;
    & svg.stroke {
      path {
        stroke: $black;
      }
    }
    & svg.fill {
      path {
        fill: $black;
      }
    }

    &:hover {
      background-color: $green-action;
      color: $black;
      & svg.stroke {
        path {
          stroke: $black;
        }
      }
      & svg.fill {
        path {
          fill: $black;
        }
      }
    }
    &:active {
      background-color: $green-light;
      color: $black;
      & svg.stroke {
        path {
          stroke: $black;
        }
      }
      & svg.fill {
        path {
          fill: $black;
        }
      }
    }
    &:disabled {
      background-color: $grey-20;
      color: $grey-8080;
      cursor: default;
      & svg.stroke {
        path {
          stroke: $grey-8080;
        }
      }
      & svg.fill {
        path {
          fill: $grey-8080;
        }
      }
    }
  }

  &.outlinedBlack {
    border-color: $black;
    background-color: transparent;
    color: $black;
    & svg.stroke {
      path {
        stroke: $black;
      }
    }
    & svg.fill {
      path {
        fill: $black;
      }
    }

    &:hover {
      border-color: transparent;
      background-color: $black;
      color: $green-action;
      & svg.stroke {
        path {
          stroke: $green-action;
        }
      }
      & svg.fill {
        path {
          fill: $green-action;
        }
      }
    }
    &:active {
      border-color: transparent;
      background-color: $black;
      color: $grey-70;
      & svg.stroke {
        path {
          stroke: $grey-70;
        }
      }
      & svg.fill {
        path {
          fill: $grey-70;
        }
      }
    }
    &:disabled {
      color: $grey-70;
      border-color: $grey-70;
      opacity: 1;
      cursor: default;

      & svg.stroke {
        path {
          stroke: $grey-70;
        }
      }
      & svg.fill {
        path {
          fill: $grey-70;
        }
      }
    }
  }
}
