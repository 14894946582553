@import "scss/resources";

@keyframes load {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.Spinner {
  width: 2rem;
  aspect-ratio: 1;
  display: grid;
  -webkit-mask: conic-gradient(from 22deg, #0003, #000);
  mask: conic-gradient(from 22deg, #0003, #000);
  animation: load 1s steps(8) infinite;
  --_g: linear-gradient(currentColor 0 0) 50%;
  background:
    var(--_g) / 34% 8% space no-repeat,
    var(--_g) / 8% 34% no-repeat space;

  &:before {
    background:
      var(--_g) / 34% 8% space no-repeat,
      var(--_g) / 8% 34% no-repeat space;
    content: "";
    transform: rotate(45deg);
  }
}
