@import "scss/resources";

.CompanyName {
  position: relative;
  width: 35.8rem;
  border-bottom: 1px solid $grey-30;

  & .input {
    width: 35.8rem;

    &:hover {
      border-color: transparent;
    }

    &:has(input:focus) {
      background: $grey-10;
      border-color: transparent;
    }
  }

  & .edit {
    position: absolute;
    right: 1.5rem;
    top: 3.5rem;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}
