@import "scss/resources";

.HeaderCell {
  @include textU(1.4rem, 400);

  display: flex;
  align-items: center;
  gap: 5px;
  height: 4rem;
  color: $black;

  & .arrow {
    cursor: pointer;

    &.up {
      transform: rotate(180deg);
    }
  }
}
