@import "scss/resources";

.MetadataModal {
  @include textU(1.6rem, 400);

  position: relative;
  width: 50rem;
  max-width: 90vw;
  max-height: 50rem;
  overflow-y: auto;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 10px;
  color: $black;
  box-shadow: 0px 1px 4px 0px #00000026;

  & .label {
    font-weight: 500;
  }

  & .subtitle {
    @include textC(1.8rem, 400);

    padding-bottom: 1.5rem;
  }

  & .row {
    padding-bottom: 1.5rem;
  }

  & .main {
    padding-top: 1.5rem;
  }

  & .validList {
    font-size: 0.5rem;

    padding-bottom: 1.5rem;
  }

  & .validItem {
    @include textU(1.6rem, 400);

    display: flex;
    gap: 1rem;

    margin-left: 1rem;

    &::before {
      content: "";
      min-width: 0.2rem;
      height: 0.2rem;
      margin-top: 1rem;
      border-radius: 100%;
      background-color: $black;
    }
  }

  & .footer {
    padding-top: 1.5rem;
    border-top: 1px solid $grey-30;
  }

  & .link {
    @include textU(1.6rem, 400);

    color: $black;

    &:hover {
      color: $grey-80;
    }
  }
}
