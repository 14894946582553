@import "scss/resources";

.Public {
  width: 35.8rem;
  min-height: calc(100svh - 10rem);
  margin: 5rem auto;
  position: relative;

  & .wrap {
    position: relative;
    min-height: calc(100svh - 10rem);
    height: 80rem;
  }

  & .link {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  & .backButton {
    margin-bottom: 1.6rem;
    cursor: pointer;

    &:hover path {
      stroke: $grey-80;
    }
  }

  & .main {
    position: absolute;
    top: 50%;
    width: 35.8rem;
    min-height: 50rem;
    transform: translateY(-50%);
  }
}
