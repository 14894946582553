@import "scss/resources";

.Inbox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  // padding: 3rem;

  & .header {
    display: flex;
    justify-content: space-between;
  }

  & .links {
    position: relative;
    list-style: none;
    width: 100%;
    padding: 1rem 0 1.4rem;
    display: flex;
    gap: 40px;
    // border-bottom: 1px solid $grey-30;

    &::before {
      content: "";
      position: absolute;
      width: calc(100% + 6rem);
      left: -3rem;
      bottom: 0;
      border-bottom: 1px solid $grey-30;
      z-index: -1;
    }

    & .link {
      @include textC(1.8rem, 400);

      padding-bottom: 1.5rem;
      color: $grey-80;

      &.active {
        color: $black;
        border-bottom: 1px solid $black;
      }
    }
  }
}
