@import "scss/resources";

.StatusCard {
  @include textU(1.4rem, 400);

  width: 35.8rem;
  padding: 2.4rem 1.6rem;
  display: flex;
  flex-direction: column;
  background-color: $grey-10;
  border: 1px solid $grey-20;
  border-radius: 10px;
  color: $black;

  &.active {
    background: $linear-black;
    color: $white;

    & .header {
      border-color: $grey-90;
    }
  }

  & .header {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.6rem;
    border-bottom: 1px solid $grey-30;

    &.noBorder {
      border: none;
      padding-bottom: 0;
    }
  }

  & .title {
    @include textU(1.6rem, 400);

    & .status {
      color: $grey-90;

      &.active {
        color: $green-action;
      }

      &.pending {
        color: $peach;
      }

      &.canceled {
        color: $grey-50;
      }

      &.paused {
        color: $red;
      }

      &.processing {
        color: $blue;
      }
    }
  }

  & .expiredDate {
    @include textU(1.6rem, 400);

    margin-top: 1.2rem;
  }

  & .paymentInfo {
    margin-top: 0.8rem;
    color: $grey-50;
  }

  & .info {
    margin-top: 1.6rem;
  }

  & .featuresList {
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.6rem;
    margin-top: 1.6rem;

    & .feature {
      display: flex;
      align-items: center;
      gap: 0.8rem;
    }
  }

  & .giftImg {
    margin-top: -1rem;
    margin-bottom: -0.3rem;
    padding-top: 1rem;
    min-height: 3.4rem;
    min-width: 2.4rem;
    max-height: 3.4rem;
    max-width: 2.4rem;
  }
}
