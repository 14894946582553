@import "scss/resources";

.SigningsTemplatesTable {
  width: calc(100% + 6rem);
  margin: 0 -3rem;

  & .list {
    @include scrollbar(0.5rem);

    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 24.8rem)
  }

  & .td {
    @include textU(1.6rem, 400);

    height: 6rem;
    display: flex;
    align-items: center;
    gap: 5px;

    &.name {
      @include textC(1.8rem, 400);

      gap: 15px;
    }
  }

  & .tr {
    padding: 0 3rem;
    display: grid;
    align-items: center;
    grid-template-columns: 4rem 3fr 1fr 1fr 1fr;
    border-bottom: 1px solid $grey-30;

    &.active {
      border-color: $black;
    }
  }

  & .actions {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 25px;
    height: 4rem;
  }

  & .action {
    @include textU(1.4rem, 400);

    height: 4rem;
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  .loader {
    @include centerContent;

    width: 100%;
    margin-top: 1rem;
  }
}
