@import "scss/resources";

.Sidebar {
  position: fixed;
  height: 100vh;
  height: -webkit-fill-available;
  width: 24rem;
  min-width: 20rem;
  max-width: 40rem;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
  background-color: $black;
  border-radius: 0px 20px 20px 0px;
  z-index: 10;

  &::-webkit-scrollbar {
    display: none;
  }

  & .resizer {
    position: absolute;
    top: 0;
    right: -0.6rem;
    width: 5rem;
    height: 100%;
    cursor: e-resize;
    background-color: transparent;
    border-radius: 40px;
    transition: all 0.1s ease-in;
    z-index: 0;

    &:hover,
    &:active {
      background-color: $green-action;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0px;
      right: 1rem;
      width: 4rem;
      border-radius: 300px;
      height: 100%;
      background-color: $black;
    }
  }

  & .main {
    height: 100%;
    padding: 3.5rem 0;
    display: flex;
    flex-direction: column;
    z-index: 10;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  & .mainLogo {
    margin-left: 3.5rem;
    margin-bottom: 3rem;
  }

  & .list {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // align-items: center;
    gap: 30px;
    width: 100%;
    margin-bottom: 6rem;

    & .navItem {
      padding-left: 3.4rem;

      &:nth-child(2) {
        padding-left: 1.2rem;
      }
    }
  }

  & .folders {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 15px;
    width: 100%;
    margin-top: 1.5rem;
    color: $grey-50;

    & ul .folder {
      margin-left: 3.5rem;
    }

    & .folder {
      @include textC(1.6rem, 400);
      @include threeDots;

      width: 100%;
      margin-left: 1rem;
      display: flex;
      align-items: center;
      gap: 4px;
      color: $grey-50;
    }
  }

  & .link {
    @include textC(1.8rem, 400);

    display: flex;
    align-items: center;
    gap: 8px;

    color: $grey-50;

    & svg {
      & path {
        stroke: $grey-50;
      }
    }

    &:hover {
      color: $green-action;

      & path {
        stroke: $green-action;
      }
    }

    &.active {
      color: $white;

      & svg {
        & path {
          stroke: $white;
        }
      }
    }
  }

  & .chevron {
    min-width: 1.2rem;
    cursor: pointer;
    // transition: all 0.1s ease-in-out;

    & path {
      fill: $grey-50;
    }

    &.opened {
      transform: rotate(90deg);
      & path {
        fill: $white;
      }
    }
  }

  & .footer {
    min-height: max-content;
    display: flex;
    flex-direction: column;
    padding-bottom: 3.5rem;
  }

  & .rights {
    @include useLinerBorderTop(1px);
    @include textU(1.2rem, 400);

    position: relative;
    padding-top: 20px;
    color: $grey-40;
    text-align: center;
  }
}
