@import "scss/resources";

.NoMemoryModal {
  width: 35.8rem;
  min-height: 45rem;
  padding: 2rem 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  color: $white;
  background:
    right/contain no-repeat url("../../../assets/img/premium-card-bg.svg"),
    $linear-black;

  & .wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
    text-align: center;
    min-width: 100%;
  }

  & .title {
    @include textC(2.4rem, 400);

    max-width: 27rem;
  }

  & .info {
    @include textU(1.6rem, 400);
  }

  & .buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  & .cloudInfo {
    @include textU(1.4rem, 400);

    display: flex;
    align-items: center;
    gap: 4px;
  }

  & .progressBar {
    position: relative;
    min-width: 100%;
    height: 0.8rem;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    border-radius: 2rem;
    background: $grey-20;

    & .value {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 8px;
      background-color: $red;
      border-radius: 20px;
    }
  }
}
