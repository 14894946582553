@import "scss/resources";

.ContactsTable {
  width: calc(100% + 6rem);
  margin: 0 -3rem;

  & .list {
    @include scrollbar(0.5rem);

    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 28rem);
  }

  & .td {
    @include textU(1.6rem, 400);

    height: 6rem;
    display: flex;
    align-items: center;
    gap: 5px;

    &.name {
      @include textC(1.8rem, 400);

      gap: 15px;
    }
  }

  & .tr {
    padding: 0 3rem;
    display: grid;
    align-items: center;
    grid-template-columns: 3rem 34rem 34rem auto;
    gap: 1rem;
    border-bottom: 1px solid $grey-30;

    &.active {
      border-color: $black;
    }
  }

  & .actions {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 25px;
  }

  & .action {
    @include textU(1.4rem, 400);

    height: 4rem;
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  & .noData {
    @include centerContent;
    @include textC(1.8rem, 400);

    width: 100%;
    height: 73rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  & .title {
    @include textC(1.8rem, 400);

    margin-left: 3rem;
    margin-bottom: 1.5rem;
  }

  & .deleteLabel {
    @include textU(1.4rem, 400);

    width: max-content;
    padding: 0.5rem 1.5rem;
    margin: 2rem 3rem 0;
    color: $black;
    background-color: $grey-20;
    border-radius: 20px;

    &.active {
      color: $red;
    }
  }
}
