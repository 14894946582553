@import "scss/resources";

.AccountButton {
  @include centerContent;

  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: $grey-10;
  border: 1px solid transparent; 
  cursor: pointer;
  transition: border-color 0.2s ease-in-out;


  &:hover {
    // opacity: 0.7;
    border-color: $black;
  }
}
