@import "scss/resources";

:root {
  //Toaster
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #f3f8ff;
  --toastify-color-success: #f4feed;
  --toastify-color-warning: #fff6ed;
  --toastify-color-error: #fff4f4;

  --toastify-text-color-info: #2986dc;
  --toastify-text-color-success: #47a80c;
  --toastify-text-color-warning: #f87238;
  --toastify-text-color-error: #f1403e;

  --toastify-toast-min-height: 44px;
  --toastify-toast-width: 358px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  scroll-behavior: smooth;
  user-select: auto;
  transition: font-size 0s;
}

input:-webkit-autofill {
  background-clip: text !important;
  -webkit-background-clip: text !important;
  border: none;
}

html {
  font-size: 62.5%; // 1 rem
  font-family: "Urbanist";
}

a {
  text-decoration: none;
}

body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;

  background: $white;
  // overflow: hidden;

  ::-webkit-scrollbar {
    height: 0.3rem;
    width: 0.3rem;
  }

  button {
    cursor: pointer;
  }

  .react-tooltip {
    @include tooltip();
  }
}
