@import "scss/resources";

.SubscriptionSuccessModal {
  width: 35.8rem;
  display: flex;
  flex-direction: column;
  background: $linear-black;
  border-radius: 20px;
  color: $white;

  & .header {
    height: 25.2rem;
    padding-left: 2rem;
    display: flex;
    align-items: center;
    background: $white right bottom no-repeat
      url("../../../assets/img/batman.svg");
    border-radius: 20px 20px 0 0;
  }

  & .content {
    @include textC(2.4rem, 400);

    width: 12.5rem;
    color: $black;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    text-align: center;

    & .title {
      @include textC(2.4rem, 400);
    }
  }

  & .info {
    @include textU(1.4rem, 400);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 2.4rem 1.6rem;

    & .title {
      @include textC(1.8rem, 400);
    }

    & .privileges {
      list-style: none;
      display: flex;
      justify-content: center;
      gap: 0 25px;
      flex-wrap: wrap;
    }

    & .privilege {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 1.6rem;
    }
  }

  & .submitButton {
    align-self: center;
    margin-top: 2.4rem;
  }
}
