@import "scss/resources";

.PremiumTrial {
  min-height: 38rem;
  display: grid;
  grid-template-columns: 1.5fr 2fr;
  color: $white;
  border-radius: 4px;

  & .info {
    min-width: 59.5rem;
    padding: 2.4rem;
    background: $black;
    border-radius: 4px 0 0 4px;
  }

  & .picture {
    width: 100%;
    background: $black center/cover no-repeat
      url("../../../assets/img/subscription/laptop.png");
    border-radius: 0 4px 4px 0;
  }

  & .header {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.6rem;
    border-bottom: 1px solid $grey-30;
  }

  & .title {
    @include textC(4.2rem, 400);

    & .active {
      color: $green-action;
    }
  }

  & .subtitle {
    @include textU(1.8rem, 400);

    margin-top: 0.8rem;
  }

  & .featuresList {
    max-width: 35.8rem;
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.6rem;
    margin-top: 4.5rem;

    & .feature {
      @include textU(1.6rem, 400);

      display: flex;
      align-items: center;
      gap: 0.8rem;
    }
  }

  & .getButton {
    width: 38.7rem;
    margin-top: 7.3rem;
  }
}
