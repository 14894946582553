@import "scss/resources";

.DocumentSidebar {
  position: fixed;
  height: 100svh;
  width: 24rem;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: scroll;
  background-color: $black;
  border-radius: 0px 20px 20px 0px;
  z-index: 11;

  &::-webkit-scrollbar {
    display: none;
  }

  & .main {
    height: 100%;
    padding: 3.5rem 0;
    display: flex;
    flex-direction: column;
    // z-index: 10;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  & .mainLogo {
    margin-left: 3rem;
    margin-bottom: 3rem;
  }

  & .title {
    @include textC(1.8rem, 400);

    margin-top: 10rem;
    color: $white;
  }

  & .signPreview {
    width: 100%;
    height: 12rem;
    margin: 1rem 0;
    cursor: pointer;

    & .placeholder {
      @include centerContent;
      @include textC(4.2rem, 400);

      width: 100%;
      height: 100%;
      border-radius: 4px;
      border: 1px dotted $grey-40;
      color: $white;
    }

    & img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      background-color: $white;
      object-fit: contain;
    }
  }

  & .initials {
    @include textU(1.6rem, 400);

    margin-bottom: 1rem;
    color: $white;
  }

  & .signList {
    list-style: none;
    position: relative;
    width: 60rem;
    max-height: 60rem;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: $white;
    border-radius: 10px;
    color: $black;
    box-shadow: 0px 1px 4px 0px #00000026;
    overflow-y: auto;

    & .signItem {
      @include centerContent;

      width: 100%;
      min-height: 20rem;
      max-height: 20rem;
      position: relative;
      border: 1px solid $grey-40;
      border-radius: 4px;
      cursor: pointer;

      &.active {
        border-color: $green-system;
        border-width: 2px;
      }

      & img {
        width: 100%;
        height: 100%;
        max-height: 20rem;
        object-fit: contain;
      }
    }

    & .signAuthor {
      @include textU(1.8rem, 400);

      margin-top: 1rem;
      color: $grey-90;
    }
  }

  & .addSignature {
    list-style: none;
    position: relative;
    width: 60rem;
    height: 60rem;
    padding: 2rem;
    background: $white;
    border-radius: 10px;
    color: $black;
    box-shadow: 0px 1px 4px 0px #00000026;
  }

  & .deleteSign {
    position: absolute;
    right: 1rem;
    top: 1rem;
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }

  & .canvasRect {
    border: 1px solid $grey-40;
    margin-bottom: 1rem;
  }

  & .saveButton {
    margin-top: 5rem;
  }

  & .recipients {
    margin-top: 2rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;

    & * {
      color: $white !important;
    }
  }
}
