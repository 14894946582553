@import "scss/resources";

.ModalHeader {
  // padding-bottom: 1.5rem;

  & .close {
    position: absolute;
    top: 2rem;
    right: 2rem;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  & .title {
    @include textC(2.4rem, 400);
    @include threeDots;
  }

  & .info {
    @include textU(1.6rem, 400);

    margin-top: 0.5rem;
  }

  & .infoWrap {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $grey-30;
  }
}
