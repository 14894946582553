@import "scss/resources";

.Search {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  width: 100%;
  padding: 0 1.6rem;
  background: $grey-10;
  border: 1px solid $grey-10;
  border-radius: 10px;
  color: $black;
  transition: all 0.1s ease-in-out;

  & svg {
    path {
      stroke: $grey-70;
      transition: all 0.1s ease-in-out;
    }
  }

  &::placeholder {
    color: $grey-70;
  }

  &:has(input:focus) {
    background: $white;
    border: 1px solid $grey-40;
  }

  &.disabled {
    color: $grey-40 !important;
    background: $grey-10 !important;
    border-color: $grey-10 !important;

    & .originalInput {
      color: $grey-40 !important;

      &::placeholder {
        color: $grey-40 !important;
      }
    }

    & svg {
      path {
        stroke: $grey-40;
      }
    }
  }

  & .inputWithIcon {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &.withValue {
    & .originalInput {
      color: $black;
    }

    & svg {
      path {
        stroke: $black;
      }
    }
  }

  & .originalInput {
    @include textU(1.6rem, 400);

    width: 100%;
    height: calc(100% - 2px);
    background: none;
    border: none;
    color: $grey-70;

    &:disabled {
      cursor: default;
    }
  }

  &:hover {
    border: 1px solid $grey-40;
  }

  & .clearButton {
    display: none;
  }

  &.withValue .clearButton {
    display: block;
    cursor: pointer;
  }
}
