@import "scss/resources";

.CounterInfo {
  @include textU(1.6rem, 400);

  position: fixed;
  left: 37rem;
  top: 10rem;
  width: 30rem;
  z-index: 100;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: $grey-90;

  & .button {
    height: 44px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    gap: 1rem;
    white-space: nowrap;
    border-left: 1px solid $grey-30;
    cursor: pointer;

    &:hover {
      background-color: #f0f3f9;
    }

    &.disabled {
      opacity: 0.5;
      background-color: transparent !important;
      cursor: default;
    }
  }

  & .statusWrap {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 10px;
    border-left: 1px solid $grey-30;
  }

  & .status {
    white-space: nowrap;
    width: max-content;
    padding: 5px 10px;
    color: $blue;
    border-radius: 25px;
    background-color: $blue-30;

    &.optional {
      color: $grey-90;
      background-color: $grey-20;
    }

    &.completed {
      color: $green;
      background-color: $green-20;
    }
  }
}
