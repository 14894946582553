@import "scss/resources";

.status {
  @include textU(1.6rem, 400);

  padding: 0.2rem 1.2rem;
  border-radius: 25px;

  &.NEED_TO_SIGN,
  &.IN_PROGRESS {
    background-color: $blue-30;
    color: $blue;
  }

  &.WAITING_FOR_OTHERS {
    background-color: $yellow-20;
    color: $dark-yellow-50;
  }

  &.COMPLETED {
    background-color: $green-20;
    color: $green;
  }

  &.REJECTED {
    background-color: $peach-20;
    color: $peach;
  }

  &.EXPIRED {
    background-color: $red-20;
    color: $red;
  }

  &.CANCELED {
    background-color: $grey-20;
    color: $grey-90;
  }

  &.DRAFT {
    background-color: $grey-20;
    color: $grey-90;
  }
}

.RecipientSigningHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 9.9rem;
  padding: 1.5rem 6rem 0;
  background-color: $white;
  border-bottom: 1px solid #e1e3e1;
  color: $black;
  z-index: 200;

  &::-webkit-scrollbar {
    display: none;
  }

  & .inner {
    @include centerContent;

    position: relative;
  }

  & .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.3rem;
  }

  & .docInfo {
    @include textU(1.4rem, 400);

    display: flex;
    align-items: center;
    gap: 1rem;
    color: $grey-90;
    margin-bottom: 1rem;

    & > *:not(:last-child)::after {
      content: "";
      display: inline-block;
      width: 0.4rem;
      height: 0.4rem;
      background: $grey-40;
      border-radius: 100%;
      margin-left: 1rem;
    }
  }

  & .title {
    @include textC(1.8rem, 400);

    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  & .id {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  & .backButton {
    position: absolute;
    left: 0;
  }

  & .mainLogo {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  & .actionButtons {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    gap: 1.5rem;
  }

  & .actionButton {
    position: relative;
  }

  & .action {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  & .menuButton {
    position: absolute;
    top: 2.5rem;
    right: 0;
    background: $white;
    border-color: $grey-40;
  }

  & .certified {
    @include centerContent;
    @include textU(1.4rem, 400);

    width: calc(100% + 12rem);
    height: 2.4rem;
    margin-left: -6rem;
    background-color: $blue-30;
    cursor: pointer;

    & .content {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
}

.RecipientSigningMobileHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 9.9rem;
  padding: 1.1rem 1.6rem;
  background-color: $white;
  border-bottom: 1px solid #e1e3e1;
  color: $black;
  z-index: 200;

  &.withMenu {
    height: 100svh;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  & .title {
    @include threeDots;
    @include textC(1.8rem, 400);
  }

  & .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.2rem;

    & .menuIcon {
      cursor: pointer;
      min-width: 3rem;

      &.withSpace {
        margin: 0.6rem 0;
        height: 1.8rem;
      }
    }
  }

  & .info {
    @include textU(1.4rem, 400);
    @include threeDots;

    display: flex;
    align-items: center;
    gap: 2rem;
    margin-top: 1.2rem;

    &.withMenu {
      padding-bottom: 2.4rem;
      border-bottom: 1px solid $grey-30;
    }

    &.smallMarginTop {
      margin-top: 0.5rem;
    }

    & .statusWrap {
      position: relative;
      padding: 0;
    }

    & > *:not(:last-child)::after {
      position: absolute;
      right: -1.2rem;
      top: 50%;
      content: "";
      display: inline-block;
      width: 0.4rem;
      height: 0.4rem;
      background: $grey-40;
      border-radius: 100%;
      margin-left: 1rem;
      transform: translateY(-50%);
    }

    & .sender {
      @include threeDots;
    }

    & .date {
      position: relative;
      height: 2.5rem;
      line-height: 2.5rem;
    }

    & .id {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      min-width: 20rem;
    }
  }

  & .statusFields {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  & .statusWrap {
    display: flex;
    align-items: center;
    height: 44px;
    padding: 0 10px;
    // border-left: 1px solid $grey-30;
  }

  & .statusField {
    white-space: nowrap;
    width: max-content;
    padding: 5px 10px;
    color: $blue;
    border-radius: 25px;
    background-color: $blue-30;

    &.optional {
      color: $grey-90;
      background-color: $grey-20;
    }

    &.completed {
      color: $green;
      background-color: $green-20;
    }
  }
  & .certified {
    @include centerContent;
    @include textU(1.4rem, 400);

    width: calc(100% + 12rem);
    height: 2.4rem;
    margin-left: -6rem;
    background-color: $blue-30;
    cursor: pointer;

    & .content {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
}
