@import "scss/resources";

.SubscriptionStatusModal {
  width: 35.8rem;
  padding: 2.4rem 1.6rem;
  display: flex;
  flex-direction: column;
  background: $linear-black;
  border-radius: 20px;
  color: $white;

  & .header {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 1.6rem;
    border-bottom: 1px solid $grey-90;
  }

  & .main {
    display: flex;
    flex-direction: column;
    padding-top: 1.6rem;
  }

  & .active {
    color: $green-action;
  }

  & .text {
    @include textU(1.6rem, 400);

    color: $white;
  }

  & .info {
    @include textU(1.4rem, 400);

    color: $grey-50;
  }

  & .privileges {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    gap: 0 25px;
    flex-wrap: wrap;
  }

  & .privilege {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 1.6rem;
  }
}
