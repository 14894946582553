@font-face {
  font-family: "Clash";
  font-weight: 400;
  src: url("../assets/fonts/ClashDisplay-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Clash";
  font-weight: 500;
  src: url("../assets/fonts/ClashDisplay-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-weight: 400;
  src: url("../assets/fonts/Urbanist-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Urbanist";
  font-weight: 500;
  src: url("../assets/fonts/Urbanist-Medium.ttf") format("truetype");
}

@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src: url("../assets/fonts/Roboto-Light.ttf") format("truetype");
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url("../assets/fonts/Roboto-Regular.ttf") format("truetype");
}
