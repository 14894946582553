@import "scss/resources";

.SidebarFolders {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;
  width: 100%;
  height: 0;
  color: $grey-50;
  visibility: hidden;

  &.visible {
    visibility: visible;
    height: 100%;
    margin-top: 1.5rem;
  }

  & ul .folder {
    margin-left: 3.5rem;
  }

  & .folder {
    @include textU(1.6rem, 400);
    @include threeDots;

    width: 100%;
    margin-left: 1rem;
    display: flex;
    align-items: center;
    gap: 4px;
    color: $grey-50;
  }
}
