@import "scss/resources";

.Pricing {
  & .subtitle {
    @include textU(2.4rem, 400);

    margin-bottom: 1.6rem;
    color: $grey-8080;
  }

  & .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 1.6rem;
  }

  & .title {
    @include textC(6.4rem, 400);

    max-width: 67rem;
    line-height: 7.7rem;
    color: $black;
  }

  & .switcherWrap {
    min-width: 36rem;
    width: max-content;
    display: flex;
    align-items: center;
    gap: 1.4rem;
  }

  & .switcherInfo {
    @include textU(1.6rem, 400);

    color: $green-pricing;
  }

  & .cards {
    list-style: none;
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
  }

  & .card {
    min-height: 60rem;
    padding: 5rem 2.4rem 3.2rem;
    display: grid;
    grid-template-rows: min-content auto min-content;
    grid-template-columns: 100%;
    justify-content: flex-start;
    background: $grey-20;
    border-radius: 4px;

    &.premium {
      background: $black center/cover no-repeat
        url("../../../assets/img/subscription/phone.webp");

      & .title {
        color: $white;

        & .label {
          color: $black;
        }
      }

      & .price {
        color: $green-action;
      }

      & .priceInfo {
        color: $grey-40;
      }

      & .info {
        color: $white;
      }

      & .features .feature {
        color: $white;
        opacity: 0.8;
      }
    }

    & .header {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 0.4rem;
    }

    & .title {
      @include textC(3.2rem, 400);

      display: flex;
      align-items: center;
      gap: 0.8rem;
    }

    & .price {
      @include textU(1.6rem, 400);

      color: $black;

      & span {
        @include textC(4.8rem, 400);
      }

      &.free {
        color: $grey-50;
      }
    }

    & .priceInfo {
      @include textU(1.6rem, 400);

      color: $grey-80;
    }

    & .info {
      @include textU(1.8rem, 400);

      color: $black;
    }

    & .label {
      @include textU(1.6rem, 400);

      padding: 0.4rem 1rem;
      background-color: $green-action;
      border-radius: 32px;
    }

    & .labelEmpty {
      @include textU(1.6rem, 400);

      padding: 0.4rem 1rem;
      border: 1px solid $green-action;
      border-radius: 32px;
    }

    & .features {
      margin-top: 4.8rem;
      align-self: flex-start;
      display: flex;
      flex-direction: column;
      gap: 1.6rem;

      & .feature {
        @include textU(1.8rem, 400);

        display: flex;
        align-items: flex-start;
        gap: 0.8rem;
        color: $grey-8080;

        & .icon {
          min-width: 2.4rem;
        }
      }
    }
  }
}
