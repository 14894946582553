@import "scss/resources";

.MovingItemsModal {
  position: relative;
  width: 50rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 10px;
  color: $black;
  box-shadow: 0px 1px 4px 0px #00000026;

  & .header {
    display: grid;
    grid-template-columns: 2fr auto;
    align-items: center;
    gap: 15px;
    margin: 2rem 0;
  }

  & .search {
    width: 100%;
    height: 5rem;
  }

  & .addFolder {
    min-width: 5rem;
    min-height: 5rem;
  }

  & .list {
    @include scrollbar();

    height: 27rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    // gap: 10px;
    margin-bottom: 2rem;
    overflow-y: auto;
  }

  & .listItem {
    min-height: 5.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    border-bottom: 1px solid $grey-30;
    cursor: pointer;

    &.withDir {
      min-height: 8rem;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  & .info {
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 15px;
  }

  & .folderIcon {
    min-width: 2.5rem;
  }

  & .name {
    @include threeDots;
    @include textC(1.8rem, 400);
  }

  & .date {
    @include textU(1.2rem, 400);

    min-width: max-content;
    width: 12rem;
  }

  & .submitButton {
    @include threeDots;

    display: block;
    padding: 0 2rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & .noData {
    @include textC(1.8rem, 400);

    height: 27rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: $grey-70;
  }

  & .nameWithDir {
    display: flex;
    flex-direction: column;
  }
}

.InnerBreadcrumbs {
  display: flex;
  align-items: center;
  gap: 8px;
  color: $grey-80;

  & .link {
    @include textU(1.4rem, 400);
    @include threeDots;

    max-width: 11.1rem;
  }
}
