@import "scss/resources";

.SignUp {
  display: flex;
  flex-direction: column;
  min-height: 70rem;

  & .title {
    @include textC(3.2rem, 400);

    margin-bottom: 3rem;
    color: $black;
  }

  & .agreement {
    @include textU(1.4rem, 400);

    margin-right: 5rem;
    color: $black;
  }

  & .link {
    color: $green-system;
    text-decoration: underline;

    &:hover {
      opacity: 0.7;
    }
  }

  & .submitButton {
    margin-top: 3rem;
  }

  & .logInButton {
    align-self: center;
  }

  & .info {
    @include textU(1.4rem, 400);

    display: flex;
    align-items: center;
    margin: 0.5rem 0 2rem;
    color: $black;

    &::before,
    &::after {
      flex: 1;
      content: "";
      padding: 0.5px 0;
      background-color: $grey-40;
      margin: 1rem;
    }

    &::before {
      margin-left: 0;
    }
    &::after {
      margin-right: 0;
    }

    // position: relative;
    // margin-top: 3rem;
    // background-color: $white;
    // text-align: center;
    // overflow: hidden;
    // z-index: 1;

    // & span {
    //   position: relative;
    //   display: inline-block;

    //   &:before,
    //   &:after {
    //     content: "";
    //     position: absolute;
    //     top: 50%;
    //     height: 1px;
    //     width: 100%;
    //     margin: 0 1rem;
    //     background-color: $grey-40;
    //   }

    //   &:before {
    //     right: 100%;
    //   }

    //   &:after {
    //     left: 100%;
    //   }
    // }
  }

  & .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 3rem;
    color: $black;

    & .text {
      @include textU(1.6rem, 400);
    }
  }

  & .socialButton {
    margin-bottom: 1.5rem;
  }
}
