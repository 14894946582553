@import "scss/resources";

.SelectFromMyDocs {
  @include scrollbar();

  width: calc(100% + 4rem);
  min-height: 55rem;
  max-height: 55rem;
  margin: 2rem -2rem 0;
  overflow-y: auto;

  & .tr {
    padding: 0 2rem;
    display: grid;
    align-items: center;
    grid-template-columns: 3fr 1fr 1fr 1fr;
    border-bottom: 1px solid $grey-30;

    &.header {
      position: sticky;
      top: -7px;
      z-index: 3;
      background-color: $white;
    }
  }

  & .title {
    @include textC(1.8rem, 400);

    margin-left: 2rem;
    margin-bottom: 1.5rem;
  }

  & .innerFolders {
    visibility: hidden;

    & .visible {
      visibility: visible;
    }
  }

  padding-bottom: 10rem;
}
