@import "scss/resources";

.ContextMenu {
  @include textU(1.4rem, 400);

  position: absolute;
  top: 3rem;
  right: 1rem;
  // width: 13rem;
  width: max-content;
  padding: 1rem;
  background: $black;
  border: 1px solid $grey-90;
  border-radius: 10px;
  color: $grey-50;
  z-index: 100;

  &.light {
    background: $white;
    border: 1px solid $grey-40;
    color: $black;
  }

  & .menuList {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 15px;

    & .menuItem {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      &:hover {
        color: $white;
        & svg {
          & path {
            stroke: $white;
          }
        }

        &.light {
          color: $black;
          opacity: 0.7;
          & svg {
            & path {
              stroke: $black;
            }
          }
        }
      }
    }
  }
}
