@import "scss/resources";

.Toast {
  @include textU(1.6rem, 400);

  border-radius: 10px;
}

.ToastBody {
  max-width: max-content;
  margin: 0 auto;
  padding: 1rem 1.5rem;
  white-space: nowrap;
}

.CloseButton {
  @include centerContent;

  width: 3rem;
  cursor: pointer;
}
