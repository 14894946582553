@import "scss/resources";

.DeleteReasonModal {
  width: 35.8rem;
  padding: 2.4rem 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: $white;
  border-radius: 20px;
  background:
    right/contain no-repeat url("../../../assets/img/delete-reason-bg.svg"),
    $linear-black;

  & .title {
    @include textC(2.4rem, 400);

    max-width: 27rem;
    // align-self: center;
    // text-align: center;
  }

  & .reasons {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 20px;

    & .reason {
      @include textU(1.6rem, 400);
    }
  }

  & .details {
    @include textU(1.6rem, 400);

    width: 32.6rem;
    height: 10rem;
    background: $white;
    border-radius: 10px;
    padding: 1.2rem 1.6rem;
    color: $black;
    resize: none;

    &::placeholder {
      color: $grey-70;
    }
  }

  & .cancelButton {
    width: 100%;
    text-align: center;
  }
}
