@import "scss/resources";

.Templates {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & .button {
    padding: 2rem 1.5rem;
  }
}
