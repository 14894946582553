@import "scss/resources";

.RecipientSigningFooter {
  @include centerContent;

  position: relative;
  width: 100%;
  height: 8rem;
  z-index: 101;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  align-items: center;
  padding: 1.5rem;
  background-color: $blue-30;
  box-shadow: 0px 1px 4px 0px #00000026;

  &.completed {
    background-color: $green-20;
    gap: 5rem;
  }

  &.mobile {
    gap: 1.5rem;
    height: max-content;
  }

  & .text {
    @include textC(1.8rem, 400);

    max-width: 30rem;

    &.mobile {
      text-align: center;
    }
  }

  & .additionalText {
    @include textU(1.4rem, 500);

    &.mobile {
      text-align: center;
    }
  }

  & .buttonsWrap {
    max-width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  & .pairButton {
    width: 15rem;
    height: 5rem;
  }

  & .singleButton {
    min-width: 20rem;
    height: 5rem;
    color: $white !important;
    background-color: $blue !important;
    border: transparent;

    &.completed {
      background-color: $green !important;

      @media screen and (max-width: 768px) {
        min-width: max-content;
      }
    }

    &:hover {
      opacity: 0.8;
    }
  }

  & .startButton {
    width: 20rem;
    height: 5rem;

    background-color: $black;
    border-color: $black;
    color: $white;
  }

  & .widgetButton {
    min-width: max-content;
    height: 5rem;

    background-color: $green-action;
    border-color: $green-action;
    color: $black;
  }

  & .completedText {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  & .mobileMenuWrap {
    position: relative;

    & .menuButton {
      width: 5rem;
      height: 5rem;
      background-color: transparent;
      border: 1px solid $black;
    }

    & .mobileMenu {
      position: absolute;
      bottom: 6rem;
      right: 0rem;
      width: 18rem;
      height: min-content;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      gap: 0;
      background-color: $white;
      border-radius: 20px;
      border: 1px solid $grey-40;

      & button {
        justify-content: flex-start;
      }
    }

    & .redButton {
      color: $red;
    }
  }

  & .signed {
    border-radius: 25px;
    background: $blue;
    border-color: transparent;
    pointer-events: none;

    &.optional {
      color: $grey-90;
      background-color: $grey-20;
    }

    &.completed {
      color: $green;
      background-color: $green-20;
    }
  }
}

.GoNextButton {
  position: fixed;
  left: 380px;
  z-index: 101;

  &.relative {
    position: relative;
    left: 0;

    & button {
      width: 15rem;
      height: 5rem;
    }
  }

  & button {
    width: 12rem;
    height: 4rem;
  }

  & .wrap {
    position: relative;
  }

  & .textInfo {
    @include textC(1.6rem, 400);

    white-space: nowrap;
    position: absolute;
    bottom: 8px;
    left: 130px;
  }
}
