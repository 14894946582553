@import "scss/resources";

.BreadcrumbsDocs {
  display: flex;
  align-items: center;
  gap: 8px;

  & .link {
    @include textC(1.8rem, 400);
    @include threeDots;

    max-width: 30rem;

    color: $grey-70;
    cursor: pointer;

    &.active,
    &:hover {
      color: $black;
    }
  }

  & .arrow {
    transform: rotate(180deg);
  }
}
