@import "scss/resources";

.GlobalSearchTable {
  width: 100%;

  & .list {
    @include scrollbar(0.5rem);

    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 18rem);
  }

  & .th {
    @include textU(1.4rem, 400);

    display: flex;
    align-items: center;
    gap: 5px;
    height: 4rem;
  }

  & .td {
    @include textU(1.6rem, 400);

    height: 6rem;
    display: flex;
    align-items: center;
    gap: 5px;

    &.name {
      @include textC(1.8rem, 400);

      gap: 15px;
    }
  }

  & .tr {
    padding: 0 3rem;
    display: grid;
    min-height: 5rem;
    align-items: center;
    grid-template-columns: 4rem 3fr 1fr 1fr 1fr;
    border-bottom: 1px solid $grey-30;

    &.active {
      border-color: $black;
    }
  }

  & .actions {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 25px;
  }

  & .action {
    @include textU(1.4rem, 400);

    height: 4rem;
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  & .noData {
    @include centerContent;
    @include textC(1.8rem, 400);

    width: 100%;
    height: 73rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  & .title {
    @include textC(1.8rem, 400);

    margin-left: 3rem;
    margin-bottom: 1.5rem;
  }
}

.DropZone {
  @include centerContent;

  width: calc(100% - 6rem);
  height: 100%;
  min-height: 70rem;
  margin: 3rem;
  flex-direction: column;
  gap: 15px;

  &.active {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23333' stroke-width='1' stroke-dasharray='20%2c 18' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 20px;
  }

  & .title {
    @include textC(2.4rem, 400);

    margin-top: 1.5rem;
  }

  & .info {
    @include textU(1.6rem, 400);
  }

  & .createModal {
    z-index: 110;
  }
}
