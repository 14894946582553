@import "scss/resources";

.AddRecipientModal {
  position: relative;
  width: 50rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 10px;
  color: $black;
  box-shadow: 0px 1px 4px 0px #00000026;

  & .nameInput {
    margin-top: 4rem;
  }

  & .buttons {
    display: flex;
    gap: 1.5rem;
  }

  & .inputWrap {
    position: relative;
  }

  & .suggestions {
    @include scrollbar();

    position: absolute;
    top: 8rem;
    left: 0;
    width: 22.5rem;
    max-height: 25rem;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem 0;
    border-radius: 10px;
    box-shadow: 0px 1px 4px 0px #00000026;
    z-index: 10;
    background-color: $white;

    & .contact {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 1.5rem 1rem;
      cursor: pointer;

      &:hover {
        background-color: $grey-10;
      }

      & .name {
        @include threeDots;
        @include textU(1.6rem, 400);

        max-width: 20rem;
        color: $black;
      }

      & .email {
        @include threeDots;
        @include textU(1.4rem, 400);

        max-width: 20rem;
        color: $grey-90;
      }
    }
  }

  & .deleteButton {
    color: $red !important;
  }
}
