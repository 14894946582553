@import "scss/resources";

.TrashBinTableRow {
  position: relative;
  display: grid;
  align-items: center;
  height: 8.5rem;
  grid-template-columns: 4rem 3fr 1fr 1fr 1fr;
  gap: 10px;
  padding-left: 3rem;
  padding-right: 3rem;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 3rem;
    bottom: 0;
    border-bottom: 1px solid $grey-30;
  }

  &:hover,
  &.selected {
    background: $grey-10;
  }

  &.certified {
    background: $blue-30;

    &:hover, &.selected {
      background: $blue-20;
    }
  }

  & .td {
    @include textU(1.6rem, 400);

    height: 8.5rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    color: $grey-90;

    &.black {
      color: $black;
    }

    & .name {
      @include textC(1.8rem, 400);

      display: flex;
      align-items: center;
      gap: 15px;
      cursor: pointer;
    }

    & .textName {
      @include threeDots;
    }
  }

  & .nameWithDir {
    display: flex;
    flex-direction: column;
  }
}

.InnerBreadcrumbs {
  display: flex;
  align-items: center;
  gap: 4px;
  color: $grey-80;

  & .link {
    @include textU(1.4rem, 400);
    @include threeDots;

    max-width: 11.1rem;
  }
}
