@import "scss/resources";

.Modal {
  @include useModal;

  &.noDarkness {
    background-color: transparent;
    z-index: 101;
  }

  & .container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: default;
  }
}
