@import "scss/resources";

.Select {
  @include textU(1.6rem, 400);

  width: 100%;
  margin-bottom: 2rem;
  position: relative;
  color: $black;
  cursor: pointer;

  & .menu {
    position: absolute;
    top: 7rem;
    left: 0;
    width: 100%;
    z-index: 100;
    list-style: none;
    display: flex;
    flex-direction: column;
    border: 1px solid $grey-40;
    border-radius: 10px;
    background: $grey-10;
  }

  & .label {
    @include textU(1.2rem, 400);
  }

  & .input {
    @include threeDots;

    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.6rem;
    background: $grey-10;
    border: 1px solid $grey-10;
    border-radius: 10px;
    color: $black;
    transition: all 0.1s ease-in-out;
  }

  & .option {
    border-bottom: 1px solid $grey-30;
    padding: 2rem;

    &:hover {
      background-color: $grey-30;
    }
  }

  & .arrow {
    margin-top: 2px;
    transform: rotate(270deg);

    &.down {
      transform: rotate(90deg);
    }
  }
}
