@import "scss/resources";

.Switcher {
  width: 5.5rem;
  height: 3.2rem;
  border-radius: 20px;
  background: $grey-30;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background: $green-20;
  }

  &:active {
    background: $grey-40;
    opacity: 0.2;
  }

  & .circle {
    width: 2.8rem;
    height: 2.8rem;
    margin: 0.2rem;
    border-radius: 100%;
    background: $black;
    transition: all 0.2s;
  }

  &.active {
    background: $green-action;

    &:hover {
      background: $green-system;
    }

    &:active {
      background: $green-system;
      opacity: 0.2;
    }

    & .circle {
      transform: translateX(2.2rem);
      background: $black;
    }
  }
}
