@import "scss/resources";

.RecipientsWidget {
  position: fixed;
  width: 30rem;
  height: 36.2rem;
  z-index: 101;
  right: 3rem;
  top: 16rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  background-color: $white;
  box-shadow: 0px 1px 4px 0px #00000026;
  border-radius: 10px;
  color: $black;
  transition: width 0.2s ease-in-out;

  &.relative {
    position: relative;
    width: 100%;
    padding: 0;
    margin-top: 2rem;
    box-shadow: none;
    left: 0;
    top: 0;
  }

  &.minimized {
    width: 6rem;
    padding: 1.5rem 0.5rem 0;
  }

  &.rejected {
    top: 38.5rem;
  }

  & .title {
    @include textC(1.8rem, 400);
  }

  & .recipients {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & .recipientsList {
      @include scrollbar();

      display: flex;
      flex-direction: column;
      gap: 1rem;
      max-height: 27rem;
      min-height: 27rem;
      padding-right: 1rem;
      margin-right: -1rem;
      overflow-y: auto;
      overflow-x: hidden;

      &.minimized {
        @include scrollbar(0, 0);

        max-height: 32rem;
        min-height: 32rem;
      }

      &.relative {
        min-height: 5rem;
      }
    }

    & .recipient {
      display: flex;
      align-items: center;
      gap: 1rem;

      & .card {
        width: 100%;
        max-width: 24.2rem;
        min-width: 5rem;
        height: 5.8rem;
        padding: 1rem 1.6rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.2rem;
        border-radius: 10px;
        background-color: $grey-10;

        &.active {
          border: 1px solid $black;
        }

        &.minimized {
          padding: 0.5rem;
        }

        & .name {
          @include textU(1.4rem, 400);
          @include threeDots;
        }

        & .email {
          @include textU(1.2rem, 400);
          @include threeDots;
        }
      }
    }
  }

  & .resizer {
    @include centerContent;

    position: absolute;
    top: calc(50% - 5rem);
    left: -0.8rem;
    width: 1.6rem;
    height: 10rem;
    border-radius: 44px;
    background-color: $grey-10;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;

    &.visible {
      opacity: 1;
    }

    & .arrowIcon {
      transform: rotate(180deg);

      &.rotated {
        transform: rotate(0deg);
      }
    }
  }

  & .status {
    @include centerContent;
    @include textU(1.4rem, 400);

    min-width: 10rem;
    padding: 0.2rem 1.1rem;
    border-radius: 25px;

    &.NEED_TO_SIGN {
      background-color: $blue-30;
      color: $blue;
    }

    &.SIGNED {
      background-color: $green-20;
      color: $green;
    }

    &.REJECTED {
      background-color: $peach-20;
      color: $peach;
    }

    &.NOT_SIGNED {
      background-color: $grey-20;
      color: $grey-90;
    }
  }

  & .statusButton {
    @include textU(1.4rem, 400);
    
    min-width: 10rem;
  }

  & .actionsHistory {
    @include textU(1.4rem, 400);

    position: absolute;
    bottom: 1.5rem;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    transform: translateX(-50%);
    cursor: pointer;

    &:hover {
      filter: opacity(0.8);
    }

    &.relative {
      position: relative;
      transform: translateX(0);
      left: 0;
      top: 0;
      margin-top: 1.5rem;
    }

    & .actionsHistoryText {
      text-decoration: underline;
    }
  }
}
