@import "scss/resources";

.CreatePassword {
  & .title {
    @include textC(3.2rem, 400);

    margin-bottom: 3rem;
    color: $black;
  }

  & .requirements {
    @include textU(1.4rem, 400);

    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style: none;

    & .requirement {
      display: flex;
      gap: 5px;
      align-items: center;
      color: $grey-80;

      & path {
        stroke: $grey-80;
      }

      &.success {
        color: $green-system;

        & path {
          stroke: $green-system;
        }
      }
    }
  }

  & .submitButton {
    margin-top: 2.5rem;
  }
}
