@import "scss/resources";

.FilterType {
  width: 14rem;
  position: relative;
  display: flex;
  gap: 5px;
  cursor: pointer;

  & .menu {
    position: absolute;
    top: 3rem;
    left: 0;
    width: 13.2rem;
    padding: 1rem;
    z-index: 100;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 15px;
    border: 1px solid $grey-40;
    border-radius: 10px;
    background: $white;
    // box-sizing: content-box;
  }

  & .arrow {
    margin-top: 2px;
    transform: rotate(270deg);

    &.down {
      transform: rotate(90deg);
    }
  }

  & .label {
    @include threeDots;
  }
}
