@import "scss/resources";

.LoaderFullScreen {
  &.showed {
    position: fixed;
    z-index: 500;
    width: 100%;
    height: 100%;
  }

  & .container {
    width: 20rem;
    height: 13rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    border-radius: 20px;
    background: $linear-black;
    color: $white;
    z-index: 501;

    & .title {
      @include textU(1.6rem, 400);

      color: $white;
    }
  }
}
