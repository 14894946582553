@import "scss/resources";

.RecipientsList {
  position: relative;
  width: 100%;
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  color: $black;

  & .title {
    @include textC(1.8rem, 400);
  }

  & .input {
    width: 100%;
  }

  & .inputsWrap {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    border-bottom: 1px solid $grey-20;
  }

  & .inputWrap {
    position: relative;

    &.plural {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
    }
  }

  & .clearButton {
    @include centerContent;

    width: 3.5rem;
    height: 3.5rem;
    position: absolute;
    right: -4.5rem;
    top: 50%;
    border-radius: 10px;
    border: 1px solid $grey-30;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      border-color: $grey-50;

      & svg {
        & path {
          stroke: $grey-80;
        }
      }
    }
  }

  & .recepientsLabel {
    @include textU(1.2rem, 400);

    padding-top: 1rem;
  }

  & .addRecipientButton {
    margin-top: 1rem;
  }

  & .suggestions {
    @include scrollbar();

    position: absolute;
    top: 8rem;
    left: 0;
    width: 22.5rem;
    max-height: 25rem;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem 0;
    border-radius: 10px;
    box-shadow: 0px 1px 4px 0px #00000026;
    z-index: 10;
    background-color: $white;
  }

  & .contact {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1.5rem 1rem;
    cursor: pointer;

    &:hover {
      background-color: $grey-10;
    }

    & .name {
      @include threeDots;
      @include textU(1.6rem, 400);

      max-width: 20rem;
      color: $black;
    }

    & .email {
      @include threeDots;
      @include textU(1.4rem, 400);

      max-width: 20rem;
      color: $grey-90;
    }
  }

  & .selfSigning {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.6rem;
    border: 1px solid $grey-30;
    border-radius: 10px;
    margin-bottom: 3rem;

    & .checkbox {
      display: flex;
      gap: 0.8rem;

      & .checkboxLabel {
        @include textU(1.6rem, 400);
      }
    }
  }
}
