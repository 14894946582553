@import "scss/resources";

.UploadModal {
  position: relative;
  width: 55rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 10px;
  color: $black;
  box-shadow: 0px 1px 4px 0px #00000026;

  & .success {
    color: $green;
  }

  & .error {
    color: $red;
  }

  & .header {
    display: grid;
    grid-template-columns: min-content auto min-content min-content;
    gap: 20px;

    &.fullView {
      padding-bottom: 1.5rem;
      border-bottom: 1px solid $grey-30;
    }
  }

  & .title {
    @include textC(1.8rem, 400);

    color: $black;
  }

  & .statusIcon {
    align-self: center;
    margin-right: -0.5rem;
  }

  & .arrow,
  & .close {
    align-self: start;
    cursor: pointer;

    &.up {
      transform: rotate(180deg);
    }

    &:hover {
      opacity: 0.7;
    }
  }

  & .text {
    display: flex;
    flex-direction: column;
  }

  & .info {
    @include textU(1.4rem, 400);

    color: $grey-90;
  }

  & .files {
    @include scrollbar();

    max-height: 0;
    padding: 0;
    overflow: hidden;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 25px;
    overflow-y: auto;
    transition: max-height 0.1s ease-in;

    &.fullView {
      max-height: 25rem;
      padding-top: 1.5rem;
      margin-right: -1rem;
      padding-right: 1rem;
    }

    // &::-webkit-scrollbar {
    //   height: 0.3rem;
    //   width: 0.3rem;
    // }

    // &::-webkit-scrollbar-thumb {
    //   background: $grey-40;
    //   border-radius: 10rem;
    // }

    // &::-webkit-scrollbar-track {
    //   background-color: transparent;
    // }
  }

  & .file {
    display: grid;
    grid-template-columns: min-content 4fr 2fr 12rem;
    align-items: center;
    gap: 15px;
  }

  & .circleProgress {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 100%;
    background: $green;
  }

  & .documentTitle {
    @include textC(1.8rem, 400);
    @include threeDots;
  }

  & .directory {
    @include textU(1.4rem, 400);
    @include threeDots;

    color: $grey-80;
  }

  & .status {
    @include textU(1.6rem, 400);

    justify-self: end;
    display: flex;
    align-items: center;
    gap: 15px;

    &.success {
      gap: 5px;
    }
  }
}
