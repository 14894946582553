@import "scss/resources";

.Undo {
  display: flex;
  gap: 1.5rem;
}

.CustomError {
  margin-left: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  & .title {
    @include textC(1.8rem, 400);
  }

  & .additional {
    @include textU(1.2rem, 400);

    white-space: normal;
    height: max-content;
  }
}

.UnlimitedText {
  white-space: normal;
  height: max-content;
}
