@import "scss/resources";

.RadioButton {
  position: relative;
  cursor: pointer;
  // user-select: none;

  & .hiddenRadioButton {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
    cursor: pointer;
  }

  & .checkCircle {
    position: relative;
    width: 1.6rem;
    height: 1.6rem;
    border: 1px solid $grey-50;
    border-radius: 100%;
    transition: 0.2s ease all;

    &.checked {
      border-color: $black;

      &::after {
        @include centerContent;

        position: absolute;
        top: 50%;
        left: 50%;
        content: "";
        width: 80%;
        height: 80%;
        border-radius: 100%;
        background-color: $black;
        transform: translate(-50%, -50%);
      }

      &.light {
        border-color: $white;

        &::after {
          background-color: $white;
        }
      }
    }
  }

  &.withTitle {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &.disabled {
    cursor: not-allowed;
  }

  // & .checkmark {
  //   @include centerContent;

  //   width: 20px;
  //   height: 20px;
  //   border: 1px solid $grey-50;
  //   border-radius: 5px;
  //   transition: 0.2s ease all;
  // }

  &:hover {
    // & .title {
    //   color: $black;
    // }

    & input ~ .checkmark {
      border-color: $black;
    }

    & .checkCircle {
      border-color: $black;
    }
  }

  & input:checked ~ .checkmark {
    border-color: $black;
  }

  & .title {
    @include textU(1.4rem, 400);

    color: $black;
  }
}
