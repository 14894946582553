@import "scss/resources";

.ConfirmModal {
  width: 35.8rem;
  min-height: 45rem;
  padding: 2rem 1.6rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  color: $white;
  background:
    right/contain no-repeat url("../../../assets/img/premium-card-bg.svg"),
    $linear-black;

  & .wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
    text-align: center;
  }

  & .title {
    @include textC(2.4rem, 400);

    max-width: 27rem;
  }

  & .info {
    @include textU(1.6rem, 400);
  }

  & .buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
