@import "scss/resources";

.UploadModal {
  position: fixed;
  top: auto !important;
  left: auto !important;
  bottom: 3rem !important;
  right: 3rem !important;
  transform: none !important;
  z-index: 2;
}

.DropZone {
  @include centerContent;

  width: calc(100% - 6rem);
  height: 100%;
  min-height: 70rem;
  margin: 3rem;
  flex-direction: column;
  gap: 15px;

  &.active {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='20' ry='20' stroke='%23333' stroke-width='1' stroke-dasharray='20%2c 18' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 20px;
  }

  & .title {
    @include textC(2.4rem, 400);

    margin-top: 1.5rem;
  }

  & .info {
    @include textU(1.6rem, 400);
  }
}
