@import "scss/resources";

.Settings {
  width: 100%;
  display: flex;
  flex-direction: column;

  & .menuItem {
    @include textU(1.6rem, 400);

    width: 35.8rem;
    padding: 2rem 0;
    border-bottom: 1px solid $grey-30;
    display: flex;
    align-items: center;
    gap: 8px;
    color: $black;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
      color: $black;
    }
  }
}
