@import "scss/resources";

.DocIDCopy {
  width: min-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &:hover * {
    color: $black;
  }

  &:hover img {
    filter: brightness(0.6);
  }

  & .text {
    @include textU(1.6rem, 400);
    @include threeDots;

    max-width: 11.5rem;

    &.sm {
      @include textU(1.4rem, 400);

      color: $grey-90;
    }
  }

  & .copyIcon {
    min-width: 2.5rem;
  }
}
