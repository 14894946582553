@import "scss/resources";

.TemplateCloseModal {
  position: relative;
  width: 50rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 10px;
  color: $black;
  box-shadow: 0px 1px 4px 0px #00000026;

  & .buttons {
    margin-top: 2rem;
    display: flex;
    justify-content: stretch;
    gap: 15px;
  }
}
