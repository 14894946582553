@import "scss/resources";

.LogoutDelete {
  display: flex;
  flex-direction: column;
  // gap: 30px;

  &.item {
    width: 35.8rem;
  }

  & .menuItem {
    @include textU(1.6rem, 400);

    padding: 2rem 0;
    border-bottom: 1px solid $grey-30;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    &:last-of-type {
      border-bottom: none;
    }

    &:hover {
      opacity: 0.7;
    }
  }
}

.AddInfo {
  @include textU(1.6rem, 400);

  margin-top: 0.5rem;

  & span {
    color: $red;
  }
}
