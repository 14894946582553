@import "scss/resources";

.UploadSignature {
  position: fixed;
  top: 52%;
  left: 50%;
  width: calc(100vw - 10rem);
  max-width: 112.6rem;
  height: min-content;
  background: transparent;
  z-index: 2000;
  transform: translate(-50%, -50%);

  &.active {
    top: 50%;
    height: 68.6rem;
  }

  & .inner {
    position: relative;
    width: 100%;
    height: 100%;
    padding-left: 8rem;

    &.active {
      padding-top: 23rem;
    }
  }

  & .container {
    width: calc(100vw - 18rem);
    max-width: 80rem;
    max-height: 40rem;
    display: flex;
    align-items: center;
    gap: 25px;
  }

  & .openButton {
    min-width: 14rem;
    max-width: 14rem;
  }

  & .signList {
    min-width: 64rem;
    max-width: 64rem;
    height: 26.3rem;
    overflow: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 20px;
    background-color: white;
  }

  & .signItem {
    @include centerContent;

    border: 1px solid $grey-40;
    border-radius: 4px;
    cursor: pointer;

    & img {
      width: 100%;
      height: auto;
    }
  }

  & .signPreview {
    width: 64rem;
    height: 26.3rem;
    margin-top: 2rem;
    margin-left: 16.4rem;

    & img {
      width: 100%;
      height: 100%;
    }
  }
  & .buttons {
    position: absolute;
    bottom: 2.5rem;
    right: 2rem;
    display: flex;
    gap: 10px;
  }

  & .hideColorWrapper {
    position: absolute;
    top: 16rem;
    right: 20rem;
    width: 20rem;
    height: 5rem;
    background-color: white;
  }

  & .cancelButton {
    width: 11rem;
    height: 4rem;
  }

  & .submitButton {
    width: 9rem;
    height: 4rem;
  }
}
