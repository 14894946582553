@import "scss/resources";

.NoTableData {
  @include centerContent;
  @include textC(1.8rem, 400);

  width: 100%;
  height: 73rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  color: $black;

  &.contacts {
    height: 50rem;
  }

  &.signings {
    height: 60rem;
  }

  &.requestSigning {
    height: 40rem;
  }
}
