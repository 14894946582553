@import "scss/resources";

.MyDocsTableRow {
  position: relative;
  display: grid;
  align-items: center;
  height: 8.5rem;
  grid-template-columns: 4rem 3fr 1fr 1fr 1fr;
  gap: 10px;
  padding-left: 3rem;
  padding-right: 3rem;

  &.graggable {
    position: relative;
    width: 30rem;
    height: 8.5rem;
    padding: 1.5rem 2rem;
    display: flex;
    align-items: center;
    gap: 15px;
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0px 1px 4px 0px #00000026;
    z-index: 15;

    &.packet::after {
      content: "";
      position: absolute;
      top: -0.5rem;
      left: -0.5rem;
      width: 100%;
      height: 100%;
      background-color: $white;
      border-radius: 10px;
      box-shadow: 0px 1px 4px 0px #00000026;
      z-index: -1;
    }

    &:hover {
      background: $white;
    }

    & .name {
      @include textC(1.8rem, 400);
    }

    & .counter {
      @include textU(1.4rem, 400);
      @include centerContent;

      position: absolute;
      width: 2.4rem;
      height: 2.4rem;
      top: -1.2rem;
      right: -1.2rem;
      border-radius: 8px;
      background-color: $black;
      box-shadow: 0px 1px 4px 0px #00000026;
      color: $white;
    }

    & .label {
      @include textU(1.2rem, 400);
      @include threeDots;

      position: absolute;
      width: 12.9rem;
      height: 2rem;
      top: 0.5rem;
      left: 0.5rem;
      box-shadow: 0px 1px 4px 0px #00000026;
      background-color: $grey-20;
    }
  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 3rem;
    bottom: 0;
    border-bottom: 1px solid $grey-30;
  }

  &:hover,
  &.selected {
    background: $grey-10;
  }

  &.certified {
    background: $blue-30;

    &:hover,
    &.selected {
      background: $blue-20;
    }
  }

  & .td {
    @include textU(1.6rem, 400);

    height: 8.5rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    color: $grey-90;

    &.black {
      color: $black;
    }

    & .name {
      @include textC(1.8rem, 400);

      display: grid;
      grid-template-columns: min-content auto;
      align-items: center;
      gap: 15px;
      cursor: pointer;
    }

    & .textName {
      @include threeDots;
    }
  }

  & .nameWithDir {
    @include threeDots;

    display: flex;
    flex-direction: column;
  }
}
