@import "scss/resources";

.PlansOverview {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    right: -6rem;
    top: 0;
    min-width: 22rem;
    width: calc(((100% - 32rem) / 3) + 9.6rem);
    height: 100%;
    background-color: $green-pricing;
    opacity: 0.05;
  }

  & .subtitle {
    @include textU(2.4rem, 400);

    margin-bottom: 1.6rem;
    color: $grey-8080;
  }

  & .title {
    @include textC(6.4rem, 400);

    line-height: 7.7rem;
    color: $black;
  }

  & .header {
    margin-top: 4.8rem;
    margin-bottom: 3.6rem;
    display: grid;
    grid-template-columns: 32rem repeat(3, minmax(22rem, 1fr));
    gap: 2rem;
  }

  & .switcherWrap {
    min-width: 36rem;
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.3rem;
  }

  & .switcherInfo {
    @include textU(1.6rem, 400);

    color: $green-pricing;
  }

  & .card {
    display: flex;
    flex-direction: column;

    & .title {
      @include textC(3.2rem, 400);

      display: flex;
      align-items: center;
      gap: 0.8rem;
    }

    & .price {
      @include textU(1.6rem, 400);

      color: $green-pricing;

      & span {
        @include textC(4.8rem, 400);
      }

      &.free {
        color: $grey-50;
      }
    }

    & .priceInfo {
      @include textU(1.6rem, 400);

      color: $grey-80;
    }

    & .label {
      @include textU(1.6rem, 400);

      padding: 0.4rem 1rem;
      background-color: $green-pricing;
      border-radius: 32px;
      color: $white;
      white-space: nowrap;
    }

    & .getButton {
      width: 22rem;
      margin-top: 2rem;
      z-index: 1;
    }
  }

  & .row {
    width: calc(100% + 9.6rem);
    margin-left: -4.8rem;
    padding: 0 4.8rem;
    height: 10.2rem;
    display: grid;
    grid-template-columns: 32rem repeat(3, minmax(22rem, 1fr));
    gap: 2rem;
    align-items: center;
    border-top: 1px solid $grey-40;

    &:nth-of-type(2) {
      border-color: $grey-50;
    }

    & .title {
      @include textU(2rem, 400);
    }
  }

  & .td {
    @include textU(2rem, 400);

    display: flex;
    gap: 1.6rem;
    color: $green-pricing;
  }
}
