@import "scss/resources";

.RequestSigning {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3rem 6rem;
  overflow: visible;

  & .header {
    display: grid;
    grid-template-columns: 10rem auto 10rem;
    z-index: 101;
  }

  & .breadcrumbs {
    display: flex;
    justify-content: center;
  }

  & .closeButton {
    & svg {
      width: 1.2rem;
      height: 2rem;
    }
  }

  & .rightSide {
    display: flex;
    align-items: center;
    gap: 3rem;
    margin-top: -1.5rem;
  }

  & .label {
    @include textU(1.6rem, 400);

    padding: 0.2rem 1.2rem;
    margin-right: 3rem;
    color: $grey-80;
    background-color: $grey-20;
    border-radius: 25px;
  }

  & .action {
    cursor: pointer;

    // &:hover {
    //   opacity: 0.8;
    // }
  }

  & .buttons {
    @include textU(1.4rem, 400);

    position: absolute;
    width: 15.1rem;
    height: 7.5rem;
    top: 6.5rem;
    right: 4rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid $grey-40;
    border-radius: 10px;
    padding: 1rem;
    background: $white;
    color: $black;
  }

  & .button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
      color: $grey-80;
      & .icon {
        opacity: 0.8;
      }
    }

    & .icon {
      min-width: 1.4rem;
    }
  }
}
