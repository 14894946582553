@import "scss/resources";

.SubscriptionPaymentMethod {
  width: 35.8rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 3rem;
  box-sizing: content-box;

  & .title {
    @include textC(3.2rem, 400);

    margin-bottom: 1rem;
  }

  & .card {
    @include textC(1.8rem, 400);

    width: 100%;
    height: 20rem;
    padding: 1.2rem 2.4rem 2.4rem 3.2rem;
    display: grid;
    grid-template-columns: auto min-content;
    align-items: center;
    border-radius: 10px;
    background:
      right/contain no-repeat url("../../assets/img/subscription/card-bg.svg"),
      $linear-black;
    color: $white;
    word-spacing: 1.6rem;

    & .cardExp {
      align-self: flex-end;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 0.8rem;

      & .label {
        @include textU(1.2rem, 400);

        color: $grey-50;
      }
    }
  }
}
