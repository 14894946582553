@import "scss/resources";

.RowActions {
  display: flex;
  align-items: center;
  gap: 15px;

  & .action {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  & .rowMenu {
    position: relative;
  }
}
