@import "scss/resources";

.ChangeLanguage {
  @include textU(1.6rem, 400);

  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &.item {
    width: 35.8rem;
    padding: 2rem 0;
    border-bottom: 1px solid $grey-30;

    &:last-of-type {
      border-bottom: none;
    }
  }
}
