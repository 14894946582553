@import "scss/resources";

.MoveModal {
  top: 7rem !important;
  left: 50% !important;
  transform: translateX(-50%);
}

.addInfo {
  @include textU(1.6rem, 400);

  margin-top: 1rem;
}