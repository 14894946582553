@import "scss/resources";

.RequestWidget {
  position: relative;
  width: 30rem;
  height: 60rem;
  z-index: 101;
  position: fixed;
  right: 3rem;
  top: 24rem;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1.5rem 0;
  background-color: $white;
  box-shadow: 0px 1px 4px 0px #00000026;
  border-radius: 10px;
  color: $black;
  transition: width 0.2s ease-in-out;

  &.minimized {
    width: 6rem;
    padding: 1.5rem 0.5rem 0;
  }

  & .title {
    @include threeDots;
    @include textC(1.8rem, 400);

    border-bottom: 1px solid transparent;
    margin-bottom: 1rem;
    cursor: pointer;

    &:hover {
      border-bottom: 1px solid $grey-30;
    }
  }

  & .subtitle {
    @include textC(1.8rem, 400);
  }

  & .fileInput {
    @include textC(1.8rem, 400);

    height: 2.6rem;
    margin-bottom: 1rem;
    border-radius: 0;
  }

  & .originalInput {
    @include textC(1.8rem, 400);
  }

  & .info {
    @include threeDots;
    @include textU(1.4rem, 400);

    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid $grey-30;

    & .infoContent {
      color: $grey-90;
    }

    & .infoItem {
      @include threeDots;

      min-height: 2rem;
      display: flex;
      gap: 0.5rem;
    }

    & .id {
      width: 100%;

      & .text {
        width: 100%;
      }
    }
  }

  & .recipients {
    padding-top: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    & .recipientsList {
      @include scrollbar();

      display: flex;
      flex-direction: column;
      gap: 1rem;
      max-height: 32rem;
      min-height: 32rem;
      padding-right: 1rem;
      margin-right: -1rem;
      overflow-y: auto;
      overflow-x: hidden;

      &.minimized {
        @include scrollbar(0, 0);

        max-height: 36rem;
        min-height: 36rem;
      }

      &.expanded {
        max-height: 39rem;
        min-height: 39rem;
      }
    }

    & .recipient {
      display: flex;
      align-items: center;
      gap: 1rem;

      & .card {
        width: 100%;
        max-width: 24.2rem;
        min-width: 5rem;
        height: 5.8rem;
        padding: 1rem 1.6rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0.2rem;
        border-radius: 10px;
        cursor: pointer;

        &:hover {
          filter: contrast(0.96);
        }

        &.active {
          border: 1px solid $black;
        }

        &.minimized {
          padding: 0.5rem;
        }

        & .name {
          @include textU(1.4rem, 400);
          @include threeDots;
        }

        & .email {
          @include textU(1.2rem, 400);
        }
      }

      & .editButton {
        min-width: 2rem;
        cursor: pointer;

        &:hover {
          filter: opacity(0.8);
        }
      }
    }
  }

  & .submitButton,
  & .deleteButton {
    min-height: 5rem;
  }

  & .addButton {
    width: 100%;
  }

  & .resizer {
    @include centerContent;

    position: absolute;
    top: calc(50% - 5rem);
    left: -0.8rem;
    width: 1.6rem;
    height: 10rem;
    border-radius: 44px;
    background-color: $grey-10;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;

    &.visible {
      opacity: 1;
    }

    & .arrowIcon {
      transform: rotate(180deg);

      &.rotated {
        transform: rotate(0deg);
      }
    }
  }
}
