@import "scss/resources";

.DateTimeModal {
  position: relative;
  width: 50rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 10px;
  color: $black;
  box-shadow: 0px 1px 4px 0px #00000026;

  & .checkList {
    list-style: none;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &:first-of-type {
      padding-bottom: 2rem;
      border-bottom: 1px solid $grey-30;
    }
  }

  & .buttons {
    margin-top: 2rem;
    display: flex;
    justify-content: stretch;
    gap: 15px;
  }

  & .label {
    @include textU(1.6rem, 400);
    margin-top: 2rem;
  }
}
