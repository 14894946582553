@import "scss/resources";

.Switcher {
  @include textU(1.8rem, 400);

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.8rem;
  width: 23.5rem;
  height: 5.9rem;
  border-radius: 100px;
  background: $grey-20;
  cursor: pointer;

  & .text {
    z-index: 1;

    &:hover {
      color: $green-pricing;
    }
  }

  & .ellipse {
    position: absolute;
    left: 0.4rem;
    width: 11.5rem;
    height: 5.1rem;
    // margin: 0.4rem;
    border-radius: 100px;
    background: $white;
    transition: all 0.2s;
  }

  &.active {
    & .ellipse {
      width: 11.2rem;
      left: 11.8rem;
    }
  }
}
