@import "scss/resources";

.NotFound {
  @include centerContent;
  @include textC(1.8rem, 400);

  flex-direction: column;
  gap: 3rem;
  transform: translateY(50%);

  & .title {
    @include textC(3.2rem, 400);

    margin-bottom: -1rem;
    color: $black;
  }

  
  & .info {
    @include textC(1.8rem, 400);

    color: $black;
  }
}
