@import "scss/resources";

.EnterCode {
  & .title {
    @include textC(3.2rem, 400);

    margin-bottom: 3rem;
    color: $black;
  }

  & .containerStyle {
    margin-top: 3rem;
    gap: 10px;
  }

  & .inputStyle {
    @include textC(2.4rem, 400);

    min-width: 5rem;
    height: 5rem;
    background-color: $grey-20;
    border-radius: 10px;
    border: none;

    &.inputSuccess {
      @include textC(2.4rem, 400);

      border: 1px solid $green-system;
      color: $green-system;
    }

    &.inputError {
      @include textC(2.4rem, 400);

      border: 1px solid $red;
      color: $red;
    }
  }

  & .email {
    @include textU(1.4rem, 400);

    color: $black;
  }

  & .codeWrap {
    position: relative;

    & .error {
      @include textU(1.2rem, 400);

      position: absolute;
      bottom: -2rem;
      left: 50%;
      color: $red;
      transform: translateX(-50%);
    }
  }

  & .resend {
    @include textU(1.4rem, 400);

    margin-top: 2.5rem;
    color: $black;

    & .counter {
      color: $blue;
    }
  }

  & .resendButton {
    margin-top: 2.5rem;
  }
}
