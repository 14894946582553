@import "scss/resources";

.CloudMemory {
  @include useLinerBorderTop(1px);

  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 3rem 0 2rem;
  color: $white;

  & .info {
    @include textU(1.4rem, 400);

    display: flex;
    align-items: center;
    gap: 4px;
  }

  & .progressBar {
    position: relative;
    width: 100%;
    height: 0.8rem;
    margin-bottom: 0.5rem;
    border-radius: 2rem;
    background: $grey-20;

    & .value {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 8px;
      background-color: $peach;
      border-radius: 20px;

      &.success {
        background-color: $green-action;
      }
      &.error {
        background-color: $red;
      }
    }
  }

  & .increaseButton {
    @include textU(1.4rem, 500);

    color: $green-action;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}
