@import "scss/resources";

.SidebarTip {
  position: absolute;
  top: 40px;
  left: -10px;
  z-index: 1;

  & .container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 1.6rem;
    background: linear-gradient(0deg, $black, $black),
      linear-gradient(0deg, $grey-90, $grey-90);
    border: 1px solid $grey-90;
    border-radius: 10px;
    color: $white;
    cursor: pointer;
  }

  & .title {
    @include textC(1.4rem, 400);

    color: $green-action;
  }

  & .text {
    @include textU(1.4rem, 400);

    margin: 0;
  }

  & .closeIcon {
    position: absolute;
    right: 1.6rem;
    top: 1.6rem;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}
