@import "scss/resources";

.Contacts {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 2rem;

  & .header {
    display: flex;
    justify-content: space-between;
  }

  & .search {
    width: 40rem;
    height: 5rem;
  }
}

