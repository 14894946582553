@import "scss/resources";

.TemplatesModal {
  position: relative;
  width: 102.4rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 10px;
  color: $black;
  box-shadow: 0px 1px 4px 0px #00000026;

  & .searchField {
    margin-top: 2rem;
    display: grid;
    grid-template-columns: auto;
    align-items: center;
    gap: 0.8rem;

    &.withButton {
      grid-template-columns: auto 5rem;
    }
  }

  & .search {
    width: 100%;
    height: 5rem;
  }

  & .submitButton {
    width: 25rem;
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
  }

  & .tabs {
    display: flex;
    gap: 2rem;
    align-items: center;
    margin: 2rem;

    & .tab {
      @include textC(1.8rem, 400);

      color: $grey-80;
      cursor: pointer;

      &.active {
        color: $black;
      }
    }
  }
}
