@import "scss/resources";

.PaymentMethodButton {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1.6rem 1.6rem 1.6rem 0;
  border-bottom: 1px solid $grey-30;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  & .title {
    @include textU(1.6rem, 400);

    color: $black;
  }

  & .icon {
    transform: rotate(180deg);
  }
}
