@import "scss/resources";

.ContactSelector {
  width: 100%;
  color: $black;

  & .label {
    @include textU(1.2rem, 400);

    & .labelError {
      color: $red;
    }
  }

  & .container {
    background: $grey-10;
    border-radius: 10px;
    padding: 0.8rem;
    transition: all 0.2s ease-in-out;
  }

  & .inputWrap {
    position: relative;
  }

  & .input {
    height: 4rem;
    margin: 0;
  }

  & .suggestions {
    @include scrollbar();

    position: absolute;
    top: 4.5rem;
    left: 0;
    width: 100%;
    max-height: 25rem;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem 0;
    border-radius: 10px;
    box-shadow: 0px 1px 4px 0px #00000026;
    z-index: 10;
    background-color: $white;

    & .contact {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 1.5rem 1rem;
      cursor: pointer;

      &:hover {
        background-color: $grey-10;
      }

      & .name {
        @include threeDots;
        @include textU(1.6rem, 400);

        max-width: 100%;
        color: $black;
      }

      & .email {
        @include threeDots;
        @include textU(1.4rem, 400);

        max-width: 100%;
        color: $grey-90;
      }
    }
  }

  & .recipients {
    @include scrollbar();

    display: flex;
    flex-wrap: wrap;
    gap: 0.4rem;
    max-height: 7rem;
    margin-top: 0.4rem;
    overflow-y: auto;
  }

  & .recipient {
    @include textU(1.6rem, 400);

    display: flex;
    gap: 0.8rem;
    padding: 0.5rem 1rem;
    background: $grey-40;
    border-radius: 5px;
    color: $black;
  }

  & .fakeInput {
    @include textU(1.6rem, 400);

    padding: 0.5em 1rem;
    width: 100%;
    height: 4.8rem;
    color: $grey-70;
  }

  & .clearButton {
    cursor: pointer;
  }
}
