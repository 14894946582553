@import "scss/resources";

.RejectionWidget {
  position: relative;
  width: 30rem;
  z-index: 101;
  position: fixed;
  right: 3rem;
  top: 16rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  background-color: $white;
  box-shadow: 0px 1px 4px 0px #00000026;
  border-radius: 10px;
  color: $black;
  transition: width 0.2s ease-in-out;

  &.relative {
    position: relative;
    width: 100%;
    padding: 0;
    margin-top: 2rem;
    box-shadow: none;
    left: 0;
    top: 0;
  }

  &.minimized {
    width: 6rem;
    padding: 1.5rem 0.5rem 0;
  }

  & .title {
    @include textC(1.8rem, 400);
  }

  & .resizer {
    @include centerContent;

    position: absolute;
    top: calc(50% - 5rem);
    left: -0.8rem;
    width: 1.6rem;
    height: 10rem;
    border-radius: 44px;
    background-color: $grey-10;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;

    &.visible {
      opacity: 1;
    }

    & .arrowIcon {
      transform: rotate(180deg);

      &.rotated {
        transform: rotate(0deg);
      }
    }
  }

  & .reasonText {
    @include textU(1.6rem, 400);

    height: 14.5rem;
    background: $grey-10;
    padding: 1.2rem 1.6rem;
    border-radius: 10px;
    overflow-y: auto;
  }
}
