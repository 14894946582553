@import "scss/resources";

.Loader {
  border: 2px currentcolor solid;
  border-radius: 20%;
  overflow: hidden;
  position: relative;
  &:after,
  &:before {
    content: "";
    border-radius: 50%;
    position: absolute;
    width: inherit;
    height: inherit;
    animation: loader-40 2s infinite linear;
  }
  &:before {
    border-top: 1rem currentcolor solid;
    top: -0.15em;
    left: calc(-50% - 0.15em);
    transform-origin: right center;
  }
  &:after {
    border-bottom: 1rem currentcolor solid;
    top: 0.15em;
    right: calc(-50% - 0.15em);
    transform-origin: left center;
  }
}
@keyframes loader-40 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
