@import "scss/resources";

.ContactModal {
  position: relative;
  width: 50rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 10px;
  color: $black;
  box-shadow: 0px 1px 4px 0px #00000026;

  & .inputGroup {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  & .input {
    margin: 2rem 0 2.5rem;
  }

  & .header {
    padding-bottom: 1.5rem;
  }

  & .buttons {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }
}
