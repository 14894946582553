@import "scss/resources";

.DetailedViewHeader {
  position: fixed;
  left: 0;
  width: 100%;
  padding: 1.5rem 6rem 0;
  background-color: $white;
  border-bottom: 1px solid #e1e3e1;
  color: $black;
  z-index: 200;

  &::-webkit-scrollbar {
    display: none;
  }

  & .inner {
    @include centerContent;

    position: relative;
  }

  & .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.3rem;
  }

  & .title {
    @include textC(1.8rem, 400);

    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  & .date {
    @include textU(1.4rem, 400);

    color: $grey-90;
    margin-bottom: 1rem;
  }

  & .backButton {
    position: absolute;
    left: 0;
  }

  & .actionButtons {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    gap: 1.5rem;
  }

  & .actionButton {
    position: relative;
  }

  & .action {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  & .deleteButton {
    position: absolute;
    top: 2.5rem;
    right: 0;
    background: $white;
    border-color: $grey-40;
  }

  & .certified {
    @include centerContent;
    @include textU(1.4rem, 400);

    width: calc(100% + 12rem);
    height: 2.4rem;
    margin-left: -6rem;
    background-color: $blue-30;
    cursor: pointer;

    & .content {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }

  .status {
    @include textU(1.6rem, 400);

    padding: 0.2rem 1.2rem;
    border-radius: 25px;
    background-color: $green-20;
    color: $green;
  }
}
