@import "scss/resources";

.BreadcrumbsRequestSigning {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $grey-30;

  & .label {
    @include centerContent;

    width: 2.5rem;
    height: 2.5rem;
    background-color: $grey-20;
    border-radius: 100%;
    color: $grey-80;
  }

  & .linkWrap {
    display: flex;
    align-items: center;
    padding-bottom: 2rem;

    &:not(:first-child) {
      margin-left: 2.25rem;
    }

    &.active {
      border-bottom: 1px solid $black;
    }

    &.active * {
      color: $black;

      path {
        stroke: $black;
      }
    }

    &:hover * {
      color: $black;
      border-color: $black;
    }

    &.disabled *,
    &.disabled:hover * {
      color: $grey-50;
      cursor: default;

      path {
        stroke: $grey-50;
      }
    }
  }

  & .link {
    @include textC(1.8rem, 400);
    @include threeDots;

    display: flex;
    align-items: center;
    gap: 1rem;
    color: $grey-80;
    cursor: pointer;
  }

  & .arrow {
    transform: rotate(180deg);
    margin-left: 2.25rem;
  }
}
