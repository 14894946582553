@import "scss/resources";

.ExtremelyImportantModal {
  width: 40rem;
  padding: 2.4rem 1.5rem 2rem;
  display: flex;
  flex-direction: column;
  color: $white;
  border-radius: 20px;
  background: $linear-black;

  & .title {
    @include textC(2.4rem, 400);

    display: flex;
    align-items: center;
    gap: 1rem;
    color: $red;
  }

  & .info {
    @include textU(1.6rem, 400);

    margin: 1.5rem 0;
  }

  & .scanSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
    padding: 2.4rem;
    background: $white;
    border-radius: 16px;

    & .scanInfo {
      @include textC(1.8rem, 400);

      color: $black;
      text-align: center;
    }
  }

  & .cancelButton {
    width: 100%;
    text-align: center;
    margin-top: 2.4rem;
  }
}
