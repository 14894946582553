@import "scss/resources";

.PaymentCard {
  width: 48.4rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  // grid-template-columns: 1fr 1fr;
  gap: 2.2rem;
  color: $black;

  & .link {
    grid-column: 1 / 3;
    margin: 5rem auto 3rem;
  }

  & .backButton {
    grid-column: 1 / 3;
    cursor: pointer;
  }

  & .title {
    @include textC(3.2rem, 400);
  }

  & .info {
    @include textU(1.4rem, 500);
  }

  & .paymentElement {
    min-width: 48.4rem;
  }

  & .cardElement {
    min-width: 48.4rem;
    padding: 16px 14px;
    background-color: $grey-10;
    border-radius: 10px;
  }

  & .cardElementShort {
    min-width: 23.1rem;
    padding: 16px 14px;
    background-color: $grey-10;
    border-radius: 10px;
  }

  & .fieldLong {
    width: 100%;
    grid-column: 1 / 3;
  }

  & .fieldShort {
    max-width: 50%;
  }

  & .submitButton {
    width: 100%;
  }

  & .cardsList {
    margin-bottom: 1.5rem;
    grid-column: 1 / 3;
    display: grid;
    grid-template-columns: 14rem 14rem 14rem 4rem;
    gap: 0.8rem;

    & .card {
      height: 6.4rem;
      padding: 1.2rem;
      display: flex;
      flex-direction: column;
      gap: 0.4rem;
      border: 1px solid $grey-30;
      border-radius: 5px;
      color: $grey-80;
      cursor: pointer;

      & .icon {
        min-height: 1.6rem;
      }

      &:hover,
      &.selected {
        border-color: $black;
        color: $black;

        & .icon {
          &.isPaintedFirst {
            & path {
              fill: $black;
            }
          }
          &.isPaintedSecond {
            & path:nth-of-type(2) {
              fill: $black;
            }
          }
        }
      }

      & .cardTitle {
        @include textU(1.4rem, 400);
      }
    }
  }
}
