@import "scss/resources";

.RecipientsModal {
  position: relative;
  width: 50rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  background: $white;
  border-radius: 10px;
  color: $black;
  box-shadow: 0px 1px 4px 0px #00000026;

  & .input {
    width: 100%;
    margin: 1rem 0;
  }

  & .optionalEmail {
    display: flex;
    align-items: center;
    gap: 12px;

    & div {
      width: 100%;
    }
  }

  & .clearButton {
    cursor: pointer;
  }

  & .recepientsLabel {
    @include textU(1.2rem, 400);

    padding-top: 1rem;
  }

  & .addRecipientButton {
    margin: 2rem 0;
  }
}
