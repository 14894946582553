@import "scss/resources";

.SigningsInboxTableRow {
  position: relative;
  display: grid;
  align-items: center;
  height: 8.5rem;
  grid-template-columns: 4rem 3fr 1fr 1fr 1fr;
  padding: 0 3rem;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 3rem;
    bottom: 0;
    border-bottom: 1px solid $grey-30;
  }

  &:hover,
  &.selected {
    background: $grey-10;
  }

  & .td {
    @include textU(1.6rem, 400);

    height: 8.5rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    color: $grey-90;

    &.black {
      color: $black;
    }

    & .nameWithDir {
      @include threeDots;

      display: flex;
      flex-direction: column;
    }

    & .name {
      @include textC(1.8rem, 400);

      display: grid;
      grid-template-columns: min-content auto;
      align-items: center;
      gap: 15px;
      cursor: pointer;
    }

    & .textName {
      @include threeDots;
    }
  }

  // & .fileID {
  //   @include textU(1.6rem, 400);
  //   @include threeDots;

  //   max-width: 11.5rem;
  // }

  // & .copyId {
  //   width: min-content;
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   cursor: pointer;

  //   &:hover * {
  //     color: $black;
  //   }
  // }

  & .status {
    @include textU(1.6rem, 400);

    padding: 0.2rem 1.2rem;
    border-radius: 25px;
    white-space: nowrap;;

    &.NEED_TO_SIGN {
      background-color: $blue-30;
      color: $blue;
    }

    &.WAITING_FOR_OTHERS {
      background-color: $yellow-20;
      color: $dark-yellow-50;
    }

    &.COMPLETED {
      background-color: $green-20;
      color: $green;
    }

    &.REJECTED {
      background-color: $peach-20;
      color: $peach;
    }

    &.EXPIRED {
      background-color: $red-20;
      color: $red;
    }

    &.CANCELED {
      background-color: $grey-20;
      color: $grey-90;
    }

    &.DRAFT {
      background-color: $grey-20;
      color: $grey-90;
    }
  }
}
