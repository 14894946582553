@import "scss/resources";

.TrashDocHeader {
  position: fixed;
  left: 0;
  height: 10rem;
  width: 100%;
  padding: 3rem 6rem;
  background-color: $white;
  color: $black;
  z-index: 200;

  &::-webkit-scrollbar {
    display: none;
  }

  & .inner {
    @include centerContent;

    position: relative;
  }

  & .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.4rem;
  }

  & .title {
    @include textC(1.8rem, 400);
  }

  & .deleteText {
    @include textU(1.4rem, 400);

    &.active {
      color: $red;
    }
  }

  & .backButton {
    position: absolute;
    left: 0;
  }

  & .actionButtons {
    position: absolute;
    right: 0;
    display: flex;
    gap: 1.5rem;
  }

  & .actionButton {
    width: 14rem;
  }
}
