@import "scss/resources";

.SendEmailModal {
  position: relative;
  width: 52rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background: $white;
  border-radius: 10px;
  color: $black;
  box-shadow: 0px 1px 4px 0px #00000026;

  & .inputWrapper {
    width: 100%;
    margin-bottom: 0;
  }

  & .message {
    & .label {
      @include textU(1.2rem, 400);
    }

    & .labelError {
      color: $red;
    }

    & textarea {
      @include textU(1.6rem, 400);
      @include scrollbar;

      width: 100%;
      height: 11rem;
      background: $grey-10;
      border-radius: 10px;
      padding: 1.2rem 1.6rem;
      color: $black;
      resize: none;
      border: none;

      &::placeholder {
        color: $grey-70;
      }

      &:focus {
        background: $white;
        border: 1px solid $grey-40;
      }

      &:hover {
        border: 1px solid $grey-40;
      }
    }
  }

  & .filesTitle {
    @include textC(1.8rem, 400);
  }

  & .filesHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & .addFileButton {
    font-size: 1.4rem;
    gap: 5px;

    & svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  & .uploadedFiles {
    @include scrollbar;

    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 1rem;
    min-height: 12rem;
    max-height: 12rem;
    overflow-y: auto;
  }

  & .uploadedFile {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid $grey-20;
    gap: 0.5rem;

    & .fileImg {
      position: relative;
      width: min-content;
      padding: 0 0.5rem;

      & .clearIcon {
        position: absolute;
        top: -0.8rem;
        right: -0.8rem;
        cursor: pointer;
      }
    }

    & .fileTitle {
      @include textU(1rem, 400);

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      max-width: 10rem;
    }
  }

  & .buttons {
    display: flex;
    justify-content: stretch;
    gap: 15px;
  }
}
