@import "scss/resources";

.ForgotPassword {
  display: flex;
  flex-direction: column;

  & .title {
    @include textC(3.2rem, 400);

    margin-bottom: 1rem;
    color: $black;
  }

  & .subtitle {
    @include textU(1.4rem, 600);

    margin-bottom: 0.5rem;
    font-weight: 700;
    color: $black;
  }

  & .info {
    @include textU(1.4rem, 400);

    color: $black;
  }

  & .privateInfo {
    @include textU(1.6rem, 400);

    margin-bottom: 2.5rem;
    color: $black;
  }

  & .submitButton {
    margin-top: 1rem;
  }
}
