@import "scss/resources";

.SignatureWidget {
  position: relative;
  width: 30rem;
  z-index: 101;
  position: fixed;
  right: 3rem;
  top: 54rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  background-color: $white;
  box-shadow: 0px 1px 4px 0px #00000026;
  border-radius: 10px;
  color: $black;
  transition: width 0.2s ease-in-out;

  &.minimized {
    width: 6rem;
    padding: 1.5rem 0.5rem 0;
  }

  &.relative {
    position: relative;
    width: 100%;
    padding: 0;
    margin-top: 2rem;
    box-shadow: none;
    left: 0;
    top: 0;
    padding-bottom: 2rem;
    border-bottom: 1px solid $grey-30;
  }

  & .title {
    @include textC(1.8rem, 400);
  }

  & .resizer {
    @include centerContent;

    position: absolute;
    top: calc(50% - 5rem);
    left: -0.8rem;
    width: 1.6rem;
    height: 10rem;
    border-radius: 44px;
    background-color: $grey-10;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;

    &.visible {
      opacity: 1;
    }

    & .arrowIcon {
      transform: rotate(180deg);

      &.rotated {
        transform: rotate(0deg);
      }
    }
  }

  & .signBoxes {
    display: flex;
    gap: 1rem;
  }

  & .signBox {
    @include centerContent;

    position: relative;
    width: 100%;
    height: 8rem;
    background: $grey-10;
    border-radius: 5px;
    cursor: pointer;

    & .signImage {
      max-width: 80%;
      max-height: 80%;
    }

    & .signPlaceholder {
      @include textU(1.8rem, 400);

      color: $grey-50;
    }

    & .changeButton {
      @include textU(1.6rem, 400);

      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
    }
  }

  & .buttons {
    display: flex;
    gap: 1rem;
  }

  & .cancelButton {
    color: $red;
  }
}
