@import "scss/resources";

.SigningsTemplatesTableRow {
  position: relative;
  display: grid;
  align-items: center;
  height: 8.5rem;
  grid-template-columns: 4rem 3fr 1fr 1fr 1fr;
  padding: 0 3rem;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    left: 3rem;
    bottom: 0;
    border-bottom: 1px solid $grey-30;
  }

  &:hover,
  &.selected {
    background: $grey-10;
  }

  & .td {
    @include textU(1.6rem, 400);

    height: 8.5rem;
    padding-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    color: $grey-90;

    &.black {
      color: $black;
    }

    & .name {
      @include textC(1.8rem, 400);

      display: grid;
      grid-template-columns: min-content auto;
      align-items: center;
      gap: 15px;
      cursor: pointer;
    }

    & .textName {
      @include threeDots;
    }
  }

  & .status {
    @include textU(1.6rem, 400);

    padding: 0.2rem 1.2rem;
    border-radius: 25px;
    background-color: $purple-20;
    color: $purple-50;
  }
}
