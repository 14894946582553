@import "scss/resources";

.TryPro {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 2.1rem 1.6rem;
  background:
    right/contain no-repeat url("../../assets/img/banner-bg.svg"),
    $linear-black;
  border-radius: 10px;
  color: $white;
  cursor: pointer;

  & .title {
    @include textC(1.8rem, 400);

    max-width: 15rem;

    & .active {
      color: $green-action;
    }
  }

  & .text {
    @include textU(1.4rem, 400);

    margin: 0;
  }
}
