@import "scss/resources";

.RecipientsUploadFile {
  & .title {
    @include textC(1.8rem, 400);

    margin-bottom: 1rem;
    color: $black;
  }

  & .fileZone {
    min-height: 24rem;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23D9D9D9' stroke-width='2' stroke-dasharray='10%2c 11' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 5px;
  }

  & .button {
    width: 17.5rem;
    padding: 3rem 0;
    color: $black;
    justify-content: flex-start;
  }

  & .controlButton {
    padding-top: 3.7rem;
    border-bottom: 1px solid $grey-30;
  }

  & .uploadButton {
    width: 17.5rem;
    position: relative;

    &:hover button {
      cursor: pointer;
      color: $grey-80;

      & svg {
        path {
          stroke: $grey-80;
        }
      }
    }

    & .fileInput {
      width: 100%;
      height: 100%;
      position: absolute;
      color: transparent;
      cursor: pointer;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      z-index: 2;

      &::-webkit-file-upload-button {
        display: none;
      }
      &::file-selector-button {
        display: none;
      }
    }

    & .controlButton {
      width: 17.5rem;
    }
  }

  & .textInfo {
    @include textU(1.4rem, 400);

    margin-top: 3rem;
    color: $grey-80;
  }

  & .uploadingWrap {
    @include centerContent;

    min-height: 24rem;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23D9D9D9' stroke-width='2' stroke-dasharray='10%2c 11' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 5px;

    & .uploading {
      @include textU(1.6rem, 400);

      display: flex;
      gap: 1rem;
    }
  }
}

.DropZone {
  & .activeZone {
    @include centerContent;

    width: 100%;
    height: 100%;
    min-height: 24rem;
    flex-direction: column;
    gap: 1rem;
  }

  &.active {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23121212' stroke-width='2' stroke-dasharray='10%2c 11' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 5px;
  }

  & .title {
    @include textC(1.8rem, 400);

    color: $black;
  }

  & .info {
    @include textU(1.4rem, 400);

    color: $grey-80;
  }
}
