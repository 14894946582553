@import "scss/resources";

.SidebarFolder {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;

  & .link {
    @include textU(1.6rem, 400);

    max-width: calc(100% - 5rem);
    display: flex;
    align-items: center;
    gap: 6px;
    color: $grey-50;

    & span {
      @include threeDots;
    }

    & svg {
      & path {
        stroke: $grey-50;
      }
    }

    &:hover {
      color: $white;

      & path {
        stroke: $white;
        filter: brightness(1.2);
      }
    }

    &.active {
      color: $white;

      & svg {
        & path {
          stroke: $white;
        }
      }
    }

    &.root {
      @include textC(1.8rem, 400);

      gap: 8px;

      & svg {
        & path {
          stroke-width: 0px;
          fill: $grey-50;
        }
      }

      &:hover {
        & svg {
          & path {
            stroke-width: 0px;
            fill: $white;
            filter: brightness(1.2);
          }
        }
      }

      &.active {
        & svg {
          & path {
            stroke-width: 0px;
            fill: $white;
          }
        }
      }
    }
  }

  & .folderIcon {
    min-width: 2rem;
  }

  & .chevron {
    width: 1.2rem;
    height: 1.2rem;


    cursor: pointer;
    // transition: all 0.1s ease-in-out;

    & path {
      fill: $grey-50;
    }

    &.showed:hover {
      background: #1f1f1f;
      border-radius: 2px;
    }

    &.opened {
      transform: rotate(90deg);
      & path {
        fill: $white;
      }
    }

    &.withTip {
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 2px;
      background-color: rgba(72, 72, 72, 0.25);

      & path {
        fill: $green-action;
      }
    }
  }

  & .contextIcon {
    position: absolute;
    right: 1rem;
    top: 0;
    cursor: pointer;

    &:hover {
      background: #1f1f1f;
      border-radius: 5px;
      & path {
        fill: $white;
      }
    }

    &.active {
      background: none;
      & path {
        fill: $white;
      }
    }
  }

  & .addFolder {
    cursor: pointer;

    &:hover {
      & path {
        stroke: $white;
      }
    }
  }
}
