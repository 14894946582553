@import "scss/resources";

.DocBreadcrumbs {
  display: flex;
  align-items: center;
  gap: 4px;
  color: $grey-80;

  & .link {
    @include textU(1.4rem, 400);
    @include threeDots;

    max-width: 11.1rem;
  }
}
