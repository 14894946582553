@import "scss/resources";

.BreadcrumbsMoving {
  display: flex;
  align-items: center;

  & .link {
    @include textU(1.4rem, 400);
    @include threeDots;

    max-width: 30rem;

    color: $grey-70;
    cursor: pointer;

    &.active,
    &:hover {
      color: $black;
    }
  }

  & .arrow {
    transform: rotate(180deg);
    margin: 0 0.8rem;
  }

  & .menu {
    @include scrollbar();

    position: absolute;
    top: 18rem;
    left: 2rem;
    width: 18rem;
    max-height: 18rem;
    padding: 1rem;
    overflow-y: auto;
    border-radius: 10px;
    border: 1px solid $grey-40;
    background-color: $white;
    z-index: 1;
  }

  & .menuPathes {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  & .menuPath {
    @include threeDots;
    @include textU(1.4rem, 400);

    display: flex;
    align-items: center;
    gap: 5px;
    max-width: 15rem;
    color: $black;
    opacity: 0.8;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}
