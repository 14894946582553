@import "scss/resources";

.Signings {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 3rem 0rem;

  & .header {
    display: flex;
    justify-content: space-between;
  }

  & .rightSide {
    display: flex;
    gap: 2rem;
  }

  & .search {
    width: 40rem;
    height: 5rem;
  }

  & .reqestButton {
    width: 16rem;
  }

  & .title {
    @include textC(3.2rem, 400);
  }

  & .links {
    position: relative;
    list-style: none;
    width: 100%;
    padding: 1rem 0 1.4rem;
    display: flex;
    gap: 40px;
    // border-bottom: 1px solid $grey-30;

    // &::before {
    //   content: "";
    //   position: absolute;
    //   width: calc(100% + 6rem);
    //   left: -3rem;
    //   bottom: 0;
    //   border-bottom: 1px solid $grey-30;
    //   z-index: -1;
    // }

    & .link {
      @include textC(2.4rem, 400);

      padding-bottom: 1.5rem;
      color: $grey-80;

      &.active {
        color: $black;
        // border-bottom: 1px solid $black;
      }
    }
  }

  & .searchWrap {
    margin: 1rem -3rem;
  }
}
